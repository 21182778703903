import React from "react";
import "./index.css";
// import "./global.css";
import { createRoot } from "react-dom/client";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";


import GlobalProvider from "./context";

const root = createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>




    <GlobalProvider>


      <App />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick={true}
        pauseOnHover={true}
        draggable={true}
        progress={undefined}
        theme="light"
        type="success"
      />
    </GlobalProvider>




  </React.StrictMode>
);
