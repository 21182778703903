import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import client from "../api/client";
import CircularProgress from "../components/Generic/CircularProgress";
import MainLayouts from "../layout/MainLayouts";
const defaultFormData = {
  email: "",
  first_name: "",
  last_name: "",
  phone: "",
  how_did_you_know_about_us: "social_media",
};
export default function Engagement() {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px solid #ddd",
      padding: 10,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      marginLeft: 40, // Adjust this value according to your image size
    }),
    control: (provided, state) => ({
      ...provided,
      width: "100%",
    }),
  };
  const [formData, setFormData] = useState(defaultFormData);
  const [courses, setCourses] = useState();
  const [agreed, setAgreed] = useState(false);
  const [errors, setErrors] = useState({});
  const [courseLoading, setCourseLoading] = useState(false);

  const loadData = async () => {
    setCourseLoading(true);
    try {
      const res = (await client.get("/register-interest-event")).data;

      setCourses(res);
    } catch (error) {
      console.log(error?.response);
    }
    setCourseLoading(false);
  };
  useEffect(() => {
    loadData();
  }, []);

  const [loading, setLoading] = useState(false);
  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    try {
      // Check if formData.courses is empty or not provided

      const res = await client.post(
        "https://e1xstaging.edoubleone.com/api/register-interest-event",
        {
          ...formData,
          course: courses?.find((item) => item?._id === formData?.course)
            ?.title,
        }
      );
      if (res) {
        setFormData(defaultFormData);

        toast.success(
          "Your request was successful a mail will be sent to your email address"
        );
      }
      setTimeout(() => {
        navigate("/");
      }, 4000);
    } catch (error) {
      console.log(error);
      toast.error(
        error.message || error?.response?.data?.message || "An error occurred"
      );
    }
    setLoading(false);
  };

  return (
    <MainLayouts>
      <div
        className="w-full sm:w-1/2 mx-auto mb-[25vh] mt-[25vh]"
        // style={{
        //   display: "flex",
        //   alignItems: "center",
        //   minHeight: "70vh",
        // }}
      >
        <form
          onSubmit={handleSubmit}
          className="px-[10%] py-[6%] bg-white mx-auto rounded"
        >
          <h2 className="text-2xl font-bold text-center mb-[5%]">
            Website launch + Data deep dive Event
          </h2>
          <div className="flex justify-between gap-2 mb-[5%]">
            <input
              type="text"
              name="First Name"
              value={formData?.first_name}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  first_name: e.target.value,
                })
              }
              required
              placeholder="First Name"
              className="inline-block border-solid border-[1px] border-[#eee] py-2 px-5 rounded w-[48%] placeholder:text-xs text-sm"
            />
            {errors.first_name && (
              <p className="error text-red-400">{errors.first_name}</p>
            )}
            <input
              type="text"
              name="last_name"
              value={formData?.last_name}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  last_name: e.target.value,
                })
              }
              required
              placeholder="Last Name"
              className="inline-block border-solid border-[1px] border-[#eee] py-2 px-5 rounded w-[48%] placeholder:text-xs text-sm"
            />
            {errors.last_name && (
              <p className="text-red-400">{errors.last_name}</p>
            )}
          </div>
          <div>
            <input
              type="email"
              name="email"
              placeholder="Email"
              value={formData?.email}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  email: e.target.value,
                })
              }
              required
              className="w-full inline-block border-solid border-[1px] border-[#eee] py-2 px-5 rounded placeholder:text-xs text-sm mb-[5%]"
            />
          </div>
          {errors.email && <p className="text-red-400">{errors.email}</p>}
          <div>
            <input
              type="text"
              name="telephone"
              placeholder="Phone Number"
              value={formData?.phone}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  phone: e.target.value,
                })
              }
              required
              className="w-full inline-block border-solid border-[1px] border-[#eee] py-2 px-5 rounded placeholder:text-xs text-sm mb-[5%]"
            />
          </div>

          <button
            type="submit"
            className="w-full py-2 px-5 rounded text-sm bg-primary-color hover:bg-[#884c34] delay-0 duration-150 transition-colors ease-in text-white font-medium mt-[4%]"
          >
            {loading ? <CircularProgress /> : "SUBMIT"}
          </button>
        </form>
      </div>
    </MainLayouts>
  );
}
