import {
  Box,
  Button,
  LinearProgress,
  TextField,
  Typography,
} from "@mui/material";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { toast } from "react-toastify";
import client from "../../api/client";
import { storage } from "../../firebase";
import MainLayouts from "../../layout/MainLayouts";

const Instructors = () => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px solid #000",

      padding: 10,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      marginLeft: 40, // Adjust this value according to your image size
    }),
    control: (provided, state) => ({
      ...provided,
      width: "100%",
    }),
  };

  const [formData, setFormData] = useState({
    session_id: "",
    title: "",
    problem_file: "",
    solution_file: "",
    solution_release_date: "",
  });
  const [loading, setLoading] = useState(false);
  const [courses, setCourses] = useState();
  const [agreed, setAgreed] = useState(false);
  const [errors, setErrors] = useState({});
  const [courseLoading, setCourseLoading] = useState(false);

  const handlesubmit = async () => {
    setLoading(true);
    try {
      const res = (
        await client.post("instructor/sessions/upload-exercise", formData)
      ).data;
      toast.success("Assignment Uploaded Sucessfully");
      setDownloadURL(null);
      setDownloadURL1(null);

      setFile(null);
      setFile1(null);
      setCourses("");
      setFormData({
        ...formData,
        solution_release_date: "",
      });
    } catch (error) {
      toast.error(error.response.data?.message || error?.response?.data?.error);
    }
    setLoading(false);
  };
  const loadData = async () => {
    setCourseLoading(true);
    try {
      const res = (await client.get("/instructor/sessions")).data;

      setCourses(res || []);
    } catch (error) {
      console.log(error?.response);
    }
    setCourseLoading(false);
  };
  useEffect(() => {
    loadData();
  }, []);
  const [file, setFile] = useState(null);
  const [file1, setFile1] = useState(null);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadProgress1, setUploadProgress1] = useState(0);
  const [downloadURL, setDownloadURL] = useState(null);
  const [downloadURL1, setDownloadURL1] = useState(null);
  const [isDragging, setIsDragging] = useState(false);
  const [isDragging1, setIsDragging1] = useState(false);

  // Handle drag events
  const handleDragOver = (e) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (e) => {
    if (formData.problem_file != "") {
      e.preventDefault();
      setIsDragging(false);
      const droppedFile = e.dataTransfer.files[0];
      if (droppedFile) {
        setUploadProgress(0);
        setDownloadURL(null);
        setFile(droppedFile);
      }
    } else {
      toast.error("Upload An Assignment First Before Solution");
    }
  };

  const handleFileChange = (e) => {
    setUploadProgress(0);
    setDownloadURL(null);
    setFile(e.target.files[0]);
  };
  const handleDragOver1 = (e) => {
    e.preventDefault();
    setIsDragging1(true);
  };

  const handleDragLeave1 = () => {
    setIsDragging1(false);
  };

  const handleDrop1 = (e) => {
    e.preventDefault();
    setIsDragging1(false);
    const droppedFile1 = e.dataTransfer.files[0];
    if (droppedFile1) {
      setUploadProgress(0);
      setDownloadURL(null);
      setFile1(droppedFile1);
    }
  };

  const handleFileChange1 = (e) => {
    setUploadProgress1(0);
    setDownloadURL1("");
    setFile1(e.target.files[0]);
  };

  const handleAssignmentUpload = () => {
    if (!file1) return;

    const storageRef = ref(storage, `uploads/${file1.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file1);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Track upload progress
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress1(progress);
      },
      (error) => {
        console.error("Upload failed", error);
      },
      () => {
        // Get download URL after upload
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setFormData({
            ...formData,
            problem_file: url,
          });
          setDownloadURL1(url);
        });
      }
    );
  };
  const handleSolutionUpload = () => {
    if (!file) return;

    const storageRef = ref(storage, `uploads/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Track upload progress
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      },
      (error) => {
        console.error("Upload failed", error);
      },
      () => {
        // Get download URL after upload
        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
          setFormData({
            ...formData,
            solution_file: url,
          });
          setDownloadURL(url);
        });
      }
    );
  };
  useEffect(() => {
    if (file) {
      setDownloadURL(null);
      handleSolutionUpload();
    }
  }, [file]);
  useEffect(() => {
    if (file1) {
      setDownloadURL1(null);
      handleAssignmentUpload();
    }
  }, [file1]);

  return (
    <MainLayouts>
      <Box
        className="innersection"
        display="flex"
        justifyContent="center"
        // alignItems="center"
        flexDirection={"column"}
        minHeight="100vh"
        bgcolor="background.default"
        marginBottom={8}
        // p={3}
      >
        <h1
          style={{
            marginBottom: 60,
            fontSize: 40,
            marginTop: 150,
            color: "#D15242",
            fontWeight: 600,
            textAlign: "center",
          }}
        >
          E1x Academy Assigment Uploading Portal
        </h1>

        <div>
          <h1
            style={{
              marginTop: 20,
              fontWeight: 600,
              marginBottom: 10,
            }}
          >
            Assignment Due Date
          </h1>
          <TextField
            type="datetime-local"
            value={formData.solution_release_date}
            onChange={(e) => {
              setFormData({
                ...formData,
                solution_release_date: e.target.value,
              });
            }}
            fullWidth
            size="small"
            style={{
              marginBottom: 20,
            }}
          />
        </div>

        <div className=" mb-8">
          {courseLoading ? (
            <p>Loading Course contents........</p>
          ) : (
            <>
              <h1
                style={{
                  marginTop: 20,
                  fontWeight: 600,
                  marginBottom: 10,
                }}
              >
                Course Topic
              </h1>
              <Select
                options={
                  courses &&
                  courses?.length > 0 &&
                  courses?.map((course) => ({
                    label: (
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          borderRadius: 20,
                          color: "#000",
                        }}
                      >
                        {course?.topic}
                      </div>
                    ),
                    value: course?.id, // Ensure you provide a unique value here
                  }))
                }
                // Match the selected course
                onChange={(selectedOption) => {
                  setFormData({
                    ...formData,
                    session_id: selectedOption?.value,
                    title: courses?.find(
                      (cur) => cur.id === selectedOption?.value
                    )?.topic,
                  });
                }}
                styles={customStyles}
                isClearable
                placeholder="Select course"
                required
              />
            </>
          )}
        </div>
        <h1
          style={{
            marginTop: 20,
            fontWeight: 600,
            marginBottom: 10,
          }}
        >
          Upload Assignment
        </h1>
        <Box
          bgcolor="#f7f7f7"
          p={4}
          borderRadius={2}
          // boxShadow={3}
          // maxWidth={500}
          width="100%"
        >
          {/* Drag & Drop Section */}
          <Box
            p={4}
            border="2px dashed"
            borderColor={isDragging1 ? "primary.main" : "grey.400"}
            borderRadius={2}
            textAlign="center"
            onDragOver={handleDragOver1}
            onDragLeave={handleDragLeave1}
            onDrop={handleDrop1}
          >
            <h1
              style={{
                marginBottom: 20,
                fontSize: 25,
                fontWeight: 600,
              }}
            >
              Drag & Drop a File
            </h1>
            <Button
              onClick={() => {
                setUploadProgress1(0);
                setDownloadURL1("");
              }}
              variant="contained"
              component="label"
              color="warning"
              style={{
                background: "#D15242",
                boxShadow: "none",
              }}
            >
              Browse Files
              <input type="file" hidden onChange={handleFileChange1} />
            </Button>
            <p
              style={{
                marginTop: 20,
              }}
            >
              You can upload only one file for an assignment. Maximum file size:
              10 MB
            </p>
            {file1 && (
              <Typography variant="body2" color="textSecondary" mt={2}>
                Selected File: {file1.name}
              </Typography>
            )}
          </Box>

          {/* Upload Progress */}
          {downloadURL1 ? (
            <h1
              style={{
                marginTop: 10,
              }}
            >
              File Uploaded Successfully
            </h1>
          ) : (
            uploadProgress1 > 0 && (
              <Box mt={3}>
                <LinearProgress variant="determinate" value={uploadProgress1} />
                <Typography variant="body2" mt={2}>
                  Upload Progress: {Math.round(uploadProgress1)}%
                </Typography>
              </Box>
            )
          )}

          {/* Upload Button */}
          {/* {file && (
            <Box mt={3} textAlign="center">
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpload}
              >
                Upload
              </Button>
            </Box>
          )} */}

          {/* Download URL */}
          {/* {downloadURL && (
            <Box mt={3} textAlign="center">
              <Typography variant="body2" color="textPrimary">
                File uploaded successfully!{" "}
                <a href={downloadURL} target="_blank" rel="noopener noreferrer">
                  View File
                </a>
              </Typography>
            </Box>
          )} */}
        </Box>
        <h1
          style={{
            marginTop: 40,
            fontWeight: 600,
            marginBottom: 10,
          }}
        >
          Upload Solution
        </h1>
        <Box
          bgcolor="#f7f7f7"
          p={4}
          // mt={5}
          borderRadius={2}
          // boxShadow={3}
          // maxWidth={500}
          width="100%"
        >
          {/* Drag & Drop Section */}
          <Box
            p={4}
            border="2px dashed"
            borderColor={isDragging ? "primary.main" : "grey.400"}
            borderRadius={2}
            textAlign="center"
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
          >
            <h1
              style={{
                marginBottom: 20,
                fontSize: 25,
                fontWeight: 600,
              }}
            >
              Drag & Drop a File
            </h1>
            <Button
              disabled={formData.problem_file === ""}
              onClick={() => {
                setUploadProgress(0);
                setDownloadURL("");
              }}
              variant="contained"
              component="label"
              color="warning"
              style={{
                background: "#D15242",
                boxShadow: "none",
              }}
            >
              Browse Files
              <input type="file" hidden onChange={handleFileChange} />
            </Button>
            <p
              style={{
                marginTop: 20,
              }}
            >
              You can upload only one file for an assignment. Maximum file size:
              10 MB
            </p>
            {file && (
              <Typography variant="body2" color="textSecondary" mt={2}>
                Selected File: {file.name}
              </Typography>
            )}
          </Box>

          {/* Upload Progress */}
          {downloadURL ? (
            <h1
              style={{
                marginTop: 10,
              }}
            >
              File Uploaded Successfully
            </h1>
          ) : (
            uploadProgress > 0 && (
              <Box mt={3}>
                <LinearProgress variant="determinate" value={uploadProgress} />
                <Typography variant="body2" mt={2}>
                  Upload Progress: {Math.round(uploadProgress)}%
                </Typography>
              </Box>
            )
          )}

          {/* Upload Button */}
          {/* {file && (
            <Box mt={3} textAlign="center">
              <Button
                variant="contained"
                color="primary"
                onClick={handleUpload}
              >
                Upload
              </Button>
            </Box>
          )} */}

          {/* Download URL */}
        </Box>
        <Button
          onClick={handlesubmit}
          disabled={
            formData.problem_file === "" ||
            formData.solution_file === "" ||
            formData.solution_release_date === "" ||
            formData.session_id === "" ||
            formData?.title === ""
          }
          size="large"
          variant="contained"
          style={{
            background: "#d15242",
            minHeight: 50,
            marginTop: 50,
          }}
        >
          {loading ? "Loading..." : "Submit"}
        </Button>
      </Box>
    </MainLayouts>
  );
};

export default Instructors;
