import { Box, Grid, Skeleton } from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import CourseIcon from "../../../assets/icons/courseicon.png";
import StudentIcon from "../../../assets/icons/studenticon.png";
import teacherIcon from "../../../assets/icons/tutorIcon.png";
import Animator from "../../../components/Aminator/Animator";
import CourseComponent from "../../../components/Generic/CourseComponent";
import IsEmpty from "../../../components/Generic/isEmpty";
import { useIsMobile } from "../../../components/hooks/useIsMobile";
import { GlobalContext } from "../../../context/index";
import client from "../../../api/client";
function CoursesSection({ navigate }) {
  const {
    categoryState: { data },
    userLocation,
  } = useContext(GlobalContext);
  const mobile = useIsMobile();
  const [loading, setLoading] = useState(false);
  const [course, setCourse] = useState([]);
  const [category, setCategory] = useState(1);

  const loadData = async (id) => {
    setLoading(true);
    try {
      const res = (await client.get(`/v2/courses?category=${id}`)).data;
      setCourse(res.data);
      console.log("Courses returned:", res.data);
    } catch (error) {
      console.log("error fetching data", error.response);
    }
    setLoading(false);
  };

  useEffect(() => {
    setCategory(data[0]?.id);
    console.log("Courses returned:", data.id);
    
  }, [data]);
  
  useEffect(() => {
    loadData(category);
  }, [category]);

  const options = [
    {
      name: "Data Science",
      value: "data",
    },
    {
      name: "Web Development",
      value: "web",
    },
    {
      name: "UI/UX Designs",
      value: "ui",
    },
  ];
  const sectionArray = [
    {
      title: "Online Classes",
      img: CourseIcon,
      value: "30+",
      duration: 300,
    },
    {
      title: "Happy Students",
      img: StudentIcon,
      value: "1500+",
      duration: 400,
    },
    {
      title: "Certified Instructors",
      img: teacherIcon,
      value: "50+",
      duration: 500,
    },
    {
      title: "Positive Reviews",
      img: CourseIcon,
      value: "1500+",
      duration: 600,
    },
  ];
  return (
    <div
      className="pt-[27%] md:pt-[2.1%]  bg-[#F1F1F1] px-[0%] md:px-[7%]   pb-[27%]  md:pb-[9.1%]  "
      style={{
        // minHeight: "90vh",
        overflowX: "hidden",
      }}
    >
      <div className="innersection">
        <header className="bg-[#fff] px-4 py-6 md:px-9 rounded-lg flex justify-between ">
          {(mobile ? sectionArray?.splice(0, 3) : sectionArray)?.map(
            (cur, i) => (
              <Animator
                timeout={500}
                key={i}
                delay={cur.duration}
                style={{}}
                variant="fade"
              >
                <div
                  className=" flex gap-2  items-center"
                  style={{
                    flexDirection: mobile ? "column" : "row",
                  }}
                >
                  <img
                    src={cur.img}
                    style={{
                      height: 40,
                      width: 40,
                    }}
                  />

                  <div>
                    <h1
                      style={{
                        fontSize: 15,
                        color: "#d15424",
                        fontWeight: 600,
                        textAlign: mobile ? "center" : "start",
                      }}
                    >
                      {cur.value}
                    </h1>
                    <p
                      style={{
                        fontSize: 10,
                      }}
                    >
                      {cur.title}
                    </p>
                  </div>
                </div>
              </Animator>
            )
          )}
        </header>
        <section>
          <div
            className="flex justify-center  mt-10"
            style={{
              flexDirection: "column",
              alignItems: "center",
              gap: 10,
            }}
          >
            <div className="flex items-center gap-2">
              <div
                style={{
                  background: "#d15424",
                  height: 2,
                  width: 20,
                }}
              />
              <p
                style={{
                  color: "#aaa",
                  fontWeight: 300,
                }}
              >
                Specialization
              </p>
              <div
                style={{
                  background: "#d15424",
                  height: 2,
                  width: 20,
                }}
              />
            </div>
            
            <div
              style={{
                width: "100%",
                background: "#f4f4f4",
                height: 45,
                marginTop: 20,
                padding: mobile ? 1 : 3,
                borderRadius: 20,
                display: "flex",
                alignItems: "center",

                justifyContent: "space-between",
                marginBottom: 30,
              }}
            >
              {[...data]?.splice(0, 3)?.map((cur, i) => (
                <div
                  onClick={() => {
                    setCategory(cur.id);
                  }}
                  key={i}
                  style={{
                    width: "33%",
                    cursor: "pointer",
                    borderRadius: 20,
                    background: category === cur.id ? "#FFFFFF" : "",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <p
                    style={{
                      fontSize: 13,
                    }}
                  >
                    {mobile
                      ? cur.name
                          ?.replace("Web Development", "Web Dev")
                          ?.replace("Machine Learning", "ML")
                          .slice(0, 14)
                      : cur.name}
                  </p>
                </div>
              ))}
            </div>
            <Grid container spacing={mobile ? 2 : 6}>
              {!loading ? (
                course?.length > 0 ? (
                  <>
                    {[...course]
                      ?.sort((a, b) => b.is_free - a.is_free)
                      ?.filter((i) => i?.course_type != "online")
                      ?.splice(0, 3)
                      ?.map((cou, i) => (
                        <Animator
                          variant="fade"
                          direction="left"
                          delay={i === 0 ? 300 : i === 1 ? 400 : 500}
                          timeout={500}
                        >
                          <Grid item md={4} xs={12} key={i}>
                            <div className="flex flex-wrap gap-[1%]">
                              <CourseComponent
                                {...cou}
                                price={
                                  userLocation.data === "NG"
                                    ? cou.price_ngn
                                    : cou.price_usd
                                }
                              />
                            </div>
                          </Grid>
                        </Animator>
                      ))}
                  </>
                ) : (
                  <IsEmpty />
                )
              ) : (
                [1, 2, 3]?.map((i) => (
                  <Grid item md={4} xs={12} key={i}>
                    <Box sx={{ pt: 0.5 }}>
                      <Skeleton
                        style={{
                          borderRadius: 10,
                        }}
                        variant="rectangular"
                        width={"100%"}
                        height={250}
                      />
                      <Skeleton height={50} />
                      <Skeleton width="60%" />
                    </Box>
                  </Grid>
                ))
              )}
            </Grid>
            <div className="mt-10">
              <button
                onClick={() => {
                  navigate("/courses/categories");
                }}
                className="h-5 bg-[#d15242] text-white py-6 px-12 rounded-md  text-xs flex items-center justify-center  hover:bg-white hover:text-black"
              >
                View All Courses
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
export default CoursesSection;
