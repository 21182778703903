import { Button, Dialog, TextField, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Typewriter } from "react-simple-typewriter";
import { toast } from "react-toastify";
import NYSC from "../../assets/images/nysc.png";
import NYSCOFFER from "../../assets/images/nyscoffer2.jpeg";
import { GlobalContext } from "../../context";
import { UpdateUserInfo } from "../../context/actions/auth";
import { loadSchedule } from "../../context/actions/courses";
import MainLayouts from "../../layout/MainLayouts";
import Animator from "../Aminator/Animator";
import CircularProgress from "../Generic/CircularProgress";
import { useIsMobile } from "../hooks/useIsMobile";
import DashboardHeader from "./DashboardHeader";
import DashBoardSection from "./DashBoardSection";

export default function DashBoardNew() {
  const mobile = useIsMobile();
  const navigate = useNavigate();
  const targetRef = useRef(null);
  const targetRef1 = useRef(null);
  const [schedule, setSchedule] = useState([]);
  const [type, setType] = useState("online");
  const [formData, setFormData] = useState({ telephone: "" });
  const [open, setOpen] = useState(false);
  const {
    authState: { data },
    authDispatch,
    scheduleDispatch,
    scheduleState,
  } = useContext(GlobalContext);

  const [loading, setLoading] = useState(false);
  const HandleUpadatePhoneNumber = async () => {
    setLoading(true);
    const res = await UpdateUserInfo(formData, authDispatch);
    if (res) {
      toast.success("Phone Number Updated Successfully");
      setOpen(false);
    }
    setLoading(false);
  };
  const scrollToTarget = () => {
    targetRef?.current &&
      targetRef?.current?.scrollIntoView({ behavior: "smooth" });
  };
  const scrollToTarget1 = () => {
    targetRef1?.current &&
      targetRef1?.current?.scrollIntoView({ behavior: "smooth" });
  };

  const loadData = async () => {
    loadSchedule(scheduleDispatch);
  };
  useEffect(() => {
    loadData();
  }, []);

  useEffect(() => {
    if (data?.user?.telephone === null || data?.user?.telephone === "") {
      setOpen(true);
    }
  }, [data]);
  return (
    <MainLayouts>
      <Dialog
        fullWidth
        open={open}
        onClose={() => {
          setOpen(false);
        }}
      >
        <div
          style={{
            width: "100%",

            background: "#fff",
            padding: "30px 20px",
          }}
        >
          <h1
            style={{
              marginBottom: 10,
            }}
          >
            Update Phone Number Field{" *"}
          </h1>
          <TextField
            value={formData.telephone}
            onChange={(e) => {
              setFormData({
                ...formData,
                telephone: e.target.value,
              });
            }}
            label="Phone Number"
            fullWidth
            style={{
              marginBottom: 10,
            }}
          />
          <Button
            disabled={formData.telephone === "" || loading}
            onClick={HandleUpadatePhoneNumber}
            size="large"
            fullWidth
            variant="contained"
          >
            {loading ? <CircularProgress /> : "Update Phone Number Details"}
          </Button>
        </div>
      </Dialog>
      <div className=" mt-[16%] md:mt-[5.1%]">
        {data?.user?.is_nysc_student && (
          <div
            style={{
              minHeight: "30vh",
              background: `url(${NYSCOFFER})`,
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              position: "relative",
              display: "flex",
              alignItems: "center",

              justifyContent: "center",
              // paddingBottom: 20,
            }}
          >
            <div
              style={{
                position: "absolute",
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                background: "#000000B2",
              }}
            />
            <div
              className="innersection"
              style={{
                width: mobile ? "100%" : "70%",
                position: "relative",
                padding: "20px, 50px",
                zIndex: 1,
                color: "#fff",
              }}
            >
              <Animator delay={500} variant="fade">
                <h1
                  style={{
                    fontSize: mobile ? 36 : 48,
                    fontWeight: 600,
                    letterSpacing: -0.5,
                    textShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)",
                  }}
                >
                  {" "}
                  <Typewriter words={["Exclusive NYSC Scholarship Plan"]} />
                </h1>
              </Animator>
              <p>
                Take advantage of special discounts on career-boosting courses
                designed specifically for serving NYSC members. Access premium
                training that elevates your skills and helps you stand out in
                the job market—all at an unbeatable price.
              </p>
              <button
                onClick={() => {
                  scrollToTarget();
                }}
                className="h-5 mt-7 bg-[#d15242] text-white py-6 px-12 rounded-md  text-xs flex items-center justify-center  hover:bg-white hover:text-black"
              >
                Claim your discount now!
              </button>
            </div>
          </div>
        )}

        <header
          style={{
            minHeight: "40vh",
            background: "#f1f1f1",
            color: "#000",
            padding: "100px 0px",
          }}
        >
          <div className="innersection">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: mobile ? "column" : "row",
                color: "#000",
              }}
            >
              <div>
                {data?.user?.is_nysc_student && (
                  <Tooltip
                    describeChild
                    title="Note: You are enjoying nysc discounts "
                  >
                    <img
                      src={NYSC}
                      style={{ height: 50, objectFit: "contain", width: 100 }}
                    />
                  </Tooltip>
                )}
                <h1
                  style={{
                    // fontWeight: 550,
                    fontSize: mobile ? 30 : 40,
                    lineHeight: "40px",
                    letterSpacing: "0.02em",
                    textTransform: "uppercase",
                    color: "#000",
                    display: "flex",
                    alignItems: "center",
                    marginBottom: 20,
                  }}
                >
                  {data?.user?.firstname && (
                    <Typewriter
                      words={[
                        `Welcome Back, ${data?.user && data?.user?.firstname}`,
                      ]}
                      // loop
                    />
                  )}
                </h1>
                <p
                  style={{
                    fontWeight: 300,
                  }}
                >
                  Hey champ you have a lot to cover so lets get started...
                </p>
              </div>
              <div>
                <button
                  onClick={() => {
                    navigate("/courses/categories");
                  }}
                  style={{
                    marginBottom: 20,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: mobile ? "94vw" : "",
                    marginTop: mobile ? 30 : 0,
                  }}
                  className="text-lg  rounded text-white hover:text-black px-4 py-2 sm:px-[16px] sm:py-[10px] bg-[length:200%_100%] bg-right-bottom hover:bg-left-bottom bg-gradient-to-r from-[#fff_50%] to-[#d15424_50%] to-tex transition-all delay-0 duration-300 ease-out cursor-pointer"
                >
                  Add Courses
                </button>
              </div>
            </div>
            <DashboardHeader
              schedule={scheduleState.data}
              onClick={(e) => {
                setType(e);
                scrollToTarget();
              }}
            />{" "}
          </div>
        </header>

        <DashBoardSection
          schedule={scheduleState.data}
          targetRef={targetRef}
          targetRef1={targetRef1}
          type={type}
        />
      </div>
    </MainLayouts>
  );
}
