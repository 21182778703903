import React from "react";
import Description from "../components/LiveCourseDetail/Description";
import Learn from "../components/LiveCourseDetail/Learn";
import Benefit from "../components/LiveCourseDetail/Benefit";
import VonLiveCourseDetails from "../components/LiveCourseDetail/VonLiveCourseDetails";

// import Instructor from "../components/LiveCourseDetail/Instructor";
// import Reviews from "../components/LiveCourseDetail/Reviews";
// import Grow from "../components/LiveCourseDetail/Grow";
import Course from "../components/LiveCourseDetail/Course";
import MainLayouts from "../layout/MainLayouts";

const LiveCourseDetailPage = () => {
  return (
    <MainLayouts>
      <div className="mt-[6vh] md:mt-[1%]">
        <VonLiveCourseDetails />
        {/* <Description /> */}
        {/* <Learn /> */}
        {/* <Benefit /> */}
        {/* <Instructor /> */}
        {/* <Reviews /> */}
        {/* <Grow /> */}
        {/* <Course /> */}
      </div>
    </MainLayouts>
  );
};

export default LiveCourseDetailPage;
