import { useIsMobile } from "../hooks/useIsMobile";

function IsEmpty() {
  const mobile = useIsMobile();
  return (
    <div
      style={{
        minHeight: "40vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
        padding: mobile ? 15 : "100px 0px",
      }}
    >
      <div
        style={{
          width: mobile ? "100%" : "40%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <h1
          style={{
            fontWeight: 600,
            fontSize: mobile ? 20 : 25,
            color: "#d15242",
            marginBottom: 15,
          }}
        >
          Courses currently Unavailable
        </h1>
        {/* <p
          style={{
            color: "#aaa",
            fontSize: 17,
            marginBottom: 15,
          }}
        >
          Check back later to access this page or content
        </p>
        <p
          style={{
            fontWeight: 600,
            fontSize: 25,
            color: "#d15242",
            marginBottom: 15,
          }}
        >
          Or
        </p>
        <p
          style={{
            color: "#aaa",
            fontSize: 17,
          }}
        >
          {" "}
          Do you know you can refresh to try your luck
        </p> */}
      </div>
    </div>
  );
}
export default IsEmpty;
