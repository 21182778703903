import React from "react";
import LiveCourse from "../components/LiveCourses/Course";
import MainLayouts from "../layout/MainLayouts";

const LiveCourses = () => {
  return (
    <MainLayouts>
      <div className="custom-grow-background">
        <LiveCourse />
      </div>
    </MainLayouts>
  );
};

export default LiveCourses;
