import React, { useContext, useEffect, useState } from "react";
import { GlobalContext } from "../../context";
import { FetchCurrentWatchingCourse } from "../../utility";
import Animator from "../Aminator/Animator";
import { useIsMobile } from "../hooks/useIsMobile";
import {
  LiveCourseIcon,
  OnlineCourseIcon,
  SheduleCourseIcon,
  WatchCourseIcon,
} from "./IconButton";

export default function DashboardHeader({
  onClick = (e) => {},
  schedule,
  onClick1 = (e) => {},
}) {
  const { userCoursesState, authState } = useContext(GlobalContext);
  const mobile = useIsMobile();

  const [data, setData] = useState({});
  const currentWatchingStatus = async () => {
    const res = await FetchCurrentWatchingCourse(
      authState?.data?.id?.toString()
    );
    // console.log("prbjks", res.status);
    if (res.status) {
      setData(res.data);
    }
  };
  useEffect(() => {
    currentWatchingStatus();
  }, []);

  const courses = userCoursesState?.data;
  const sectionMyOneCourses = courses?.filter((course) => {
    return course?.course_type === "online";
  });

  const sectionMyTwoCourses = courses?.filter((course) => {
    return course?.course_type === "live";
  });

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "wrap",
        marginTop: mobile ? 30 : 70,
        gap: 10,
      }}
    >
      <Animator delay={400} timeout={400} variant={mobile ? "zoom" : "zoom"}>
        <a
          onClick={() => {
            onClick("live");
          }}
          style={{
            width: mobile ? "48%" : "23%",
          }}
        >
          <div
            // className="hover:bg-gray-700 transition duration-300"
            className="shadow-md"
            style={{
              cursor: "pointer",
              padding: "20px",

              background: "#fff",
              borderRadius: 10,
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: 20,
              // justifyContent: "center",
            }}
          >
            <LiveCourseIcon />
            <div>
              <h1
                style={{
                  color: "#000",
                  fontSize: 20,
                  fontWeight: 500,
                  marginBottom: 2,
                }}
              >
                {sectionMyTwoCourses?.length || 0}{" "}
                {sectionMyTwoCourses?.length === 1 ? "Course" : "Courses"}
              </h1>
              <p
                style={{
                  color: "#000",
                  fontSize: 14,
                  fontWeight: 300,
                }}
              >
                Live Sessions
              </p>
            </div>
          </div>
        </a>
      </Animator>
      <Animator delay={450} timeout={450} variant="zoom">
        <a
          onClick={() => {
            onClick("online");
          }}
          style={{
            width: mobile ? "48%" : "23%",
          }}
        >
          <div
            style={{
              padding: "20px",
              cursor: "pointer",
              background: "#fff",
              borderRadius: 10,
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: 20,
              // justifyContent: "center",
            }}
            className="shadow-md"
          >
            <OnlineCourseIcon />
            <div>
              <h1
                style={{
                  color: "#000",
                  fontSize: 20,
                  fontWeight: 500,
                  marginBottom: 2,
                }}
              >
                {sectionMyOneCourses?.length || 0}{" "}
                {sectionMyOneCourses?.length === 1 ? "Course" : "Courses"}
              </h1>
              <p
                style={{
                  color: "#000",
                  fontSize: 14,
                  fontWeight: 300,
                }}
              >
                Online Courses
              </p>
            </div>
          </div>
        </a>
      </Animator>
      <Animator delay={500} timeout={500} variant="zoom">
        <a
          onClick={() => {
            onClick("watch");
          }}
          style={{
            width: mobile ? "48%" : "23%",
          }}
        >
          <div
            className="shadow-md"
            style={{
              padding: "20px",
              cursor: "pointer",
              background: "#fff",
              borderRadius: 10,
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: 20,
              // justifyContent: "center",
            }}
          >
            <WatchCourseIcon />
            <div>
              <h1
                style={{
                  color: "#000",
                  fontSize: 20,
                  fontWeight: 500,
                  marginBottom: 2,
                }}
              >
                {data?.title ? 1 : 0} Course
              </h1>
              <p
                style={{
                  color: "#000",
                  fontSize: 14,
                  fontWeight: 300,
                }}
              >
                Watched Video
              </p>
            </div>
          </div>
        </a>
      </Animator>
      <Animator
        delay={600}
        timeout={600}
        direction="left"
        variant={mobile ? "zoom" : "zoom"}
      >
        <div
          className="shadow-md"
          style={{
            padding: "20px",
            width: mobile ? "48%" : "23%",
            background: "#fff",
            borderRadius: 10,
            flexWrap: "wrap",
            display: "flex",
            alignItems: "center",
            gap: 20,
            // justifyContent: "center",
          }}
        >
          <SheduleCourseIcon />
          <div>
            <h1
              style={{
                color: "#000",
                fontSize: 20,
                fontWeight: 500,
                marginBottom: 2,
              }}
            >
              {schedule?.length || 0}{" "}
              {schedule?.length === 1 ? "Course" : "Courses"}
            </h1>
            <p
              style={{
                color: "#000",
                fontSize: 14,

                fontWeight: 300,
              }}
            >
              Schedules
            </p>
          </div>
        </div>
      </Animator>
    </div>
  );
}
