import { Close } from "@mui/icons-material";
import { ButtonBase, CircularProgress } from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { getMimeType } from "../../utility";

function ReplyComponent({ data, showCancel = false, mobile }) {
  const [links, setLinks] = useState([]);
  const [linkPreview, setLinkPreview] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const extractLinks = (text) => {
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      return text.match(urlRegex);
    };

    if (data?.message) {
      setLinks(extractLinks(data.message) || []);
    }
  }, [data?.message]);

  useEffect(() => {
    if (links.length > 0 && !data.image) {
      const fetchLinkPreview = async (url) => {
        try {
          setLoading(true);
          const response = await axios.get(
            `https://api.linkpreview.net/?key=728bc1c7ce3dbc0971fa46c87a71fde7&q=${url}`
          );
          setLinkPreview(response.data);
        } catch (error) {
          console.error("Error fetching link preview", error);
        } finally {
          setLoading(false);
        }
      };

      fetchLinkPreview(links[0]);
    }
  }, [links, data?.image]);

  const renderMessageWithLinks = (message) => {
    if (!message) return null;

    const parts = message.split(/(https?:\/\/[^\s]+)/g);
    return parts.map((part, index) =>
      part.match(/(https?:\/\/[^\s]+)/g) ? (
        <a
          key={index}
          href={part}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            whiteSpace: "normal",
            wordBreak: "break-word",
            color: "#d15424",
            fontSize: mobile ? 12 : 15,
          }}
        >
          {part}
        </a>
      ) : (
        part
      )
    );
  };

  return (
    data && (
      <div
        style={{
          width: "100%",
          minWidth: mobile ? 260 : 400,
          background: "#f7f7f7",
          padding: mobile ? 8 : "10px 13px",
          borderRadius: 10,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexWrap: "wrap",
          marginTop: 10,
          marginBottom: 10,
        }}
      >
        <div>
          <h1
            style={{
              color: "#d15242",
              fontSize: mobile ? 12 : 16,
            }}
          >
            {data?.name}
          </h1>
          {links.length > 0 && !data.image && (
            <div
              style={{
                width: "100%",
                marginBottom: 5,
                whiteSpace: "normal",
                wordBreak: "break-word",
              }}
            >
              {loading ? (
                <CircularProgress
                  size={20}
                  style={{
                    color: "#d15242",
                  }}
                />
              ) : linkPreview ? (
                <div
                  style={{
                    border: "1px solid #aaa",
                    borderRadius: 10,
                    padding: 10,
                  }}
                >
                  {linkPreview.image && (
                    <img
                      src={linkPreview.image}
                      alt="link-preview"
                      style={{ width: "100%", borderRadius: 10 }}
                    />
                  )}
                  <h3
                    style={{
                      marginTop: 10,
                      fontSize: mobile ? 11 : "",
                      fontWeight: 600,
                    }}
                  >
                    {linkPreview.title}
                  </h3>
                  <p
                    style={{
                      fontSize: mobile ? 12 : "",
                    }}
                  >
                    {linkPreview.description}
                  </p>
                </div>
              ) : (
                <p>Failed to load link preview</p>
              )}
            </div>
          )}
          <p
            style={{
              fontSize: mobile ? 11 : 16,
            }}
          >
            {renderMessageWithLinks(data?.message)}
          </p>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 20,
          }}
        >
          <div>
            {data?.type === "image" && (
              <img
                src={data?.image}
                alt="chat-img"
                style={{
                  height: 65,
                  width: 65,
                  borderRadius: 10,
                  marginBottom: 5,
                  marginTop: 5,
                  objectFit: "cover",
                }}
              />
            )}
            {data?.type === "video" && (
              <video
                controls
                src={data.videoUrl}
                style={{
                  height: 65,
                  width: 65,
                  borderRadius: 10,
                  marginBottom: 5,
                }}
              />
            )}
            {data?.type === "doc" && (
              <ButtonBase style={{ width: "65%" }}>
                <iframe
                  title="doc"
                  style={{
                    overflow: "hidden",
                    height: 65,
                    width: 65,
                    marginBottom: 5,
                  }}
                  src={`https://docs.google.com/gview?url=${encodeURIComponent(
                    data?.docUrl
                  )}&embedded=true`}
                  type={getMimeType(data?.docUrl)}
                  sandbox="allow-same-origin allow-scripts"
                />
              </ButtonBase>
            )}
          </div>
          {showCancel && (
            <ButtonBase>
              <Close
                style={{
                  color: "red",
                }}
              />
            </ButtonBase>
          )}
        </div>
      </div>
    )
  );
}

export default ReplyComponent;
