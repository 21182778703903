import React, { useState, useEffect } from "react";

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { PiEyeLight, PiEyeSlashLight } from "react-icons/pi";
import "react-toastify/dist/ReactToastify.css";
import validator from "validator";
// import { Link } from "react-router-dom";
import CircularProgress from "../../Generic/CircularProgress";

export default function VonRegistrationModal({ switchToLogin }) {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [telephone, setTelephone] = useState("");
  const [loading, setLoading] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const [errors, setErrors] = useState({});

  const [showPassword, setShowPassword] = useState(false);
  const [passwordClicked, setPasswordClicked] = useState(false);

  const [csrfToken, setCsrfToken] = useState("");

  const togglePasswordVisibility = (e) => {
    e.stopPropagation(); // Prevent the event from propagating to parent elements
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const validatePasswordStrength = (password) => {
    const minLength = 8;
    const minLowercase = 1;
    const minUppercase = 1;
    const minNumbers = 1;
    const minSpecialCharacters = 1;

    if (password.length < minLength) {
      return "Password is too short";
    }

    const lowercaseCount = (password.match(/[a-z]/g) || []).length;
    const uppercaseCount = (password.match(/[A-Z]/g) || []).length;
    const numbersCount = (password.match(/[0-9]/g) || []).length;
    const specialCharactersCount = (
      password.match(/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g) || []
    ).length;

    if (lowercaseCount < minLowercase) {
      return "weak";
    }

    if (uppercaseCount < minUppercase) {
      return "MissingUppercase";
    }

    if (numbersCount < minNumbers) {
      return "weak";
    }

    if (specialCharactersCount < minSpecialCharacters) {
      return "weak";
    }

    return "Strong"; // Password meets all strength criteria
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (validator.isEmpty(firstName)) {
      errors.firstName = "First name is required";
      isValid = false;
    }

    if (validator.isEmpty(lastName)) {
      errors.lastName = "Last name is required";
      isValid = false;
    }

    if (!validator.isEmail(email)) {
      errors.email = "Invalid email address";
      isValid = false;
    }
    if (!validator.isEmpty(telephone)) {
      errors.telephone = "Invalid telephone number";
      isValid = false;
    }

    const passwordStrength = validatePasswordStrength(password);

    if (passwordStrength !== "Strong") {
      errors.password = passwordStrength;
      isValid = false;
    }

    if (!agreed) {
      errors.agreed = "You must agree to the terms and conditions";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const handleSubmit = (event) => {
    setLoading(true);
    event.preventDefault();

    const formErrors = validateForm();

    if (Object.keys(formErrors).length === 0 && agreed) {
      const passwordStrength = validatePasswordStrength(password);

      if (passwordStrength === "Strong") {
        const formData = new FormData();
        formData.set("firstname", firstName);
        formData.set("lastname", lastName);
        formData.set("email", email);
        formData.set("telephone", telephone);
        formData.set("password", password);

        setLoading(true);
        axios
          .post(
            `${process.env.REACT_APP_DOMAIN_URL}/api/auth/register`,
            formData
          )
          .then(function (response) {
            if (response.status === 200) {
              navigate("/vonverify");

              toast("Registration success");
            } else {
              let data = response.data.errors;
              let error = "";
              for (const key in data) {
                error += `${key}: ${data[key]}`;
              }
              toast(error);
            }
          })
          .catch((error) => {
            console.log(error);
            if (error.response) {
              if (error.response.data.errors) {
                setErrors(error.response.data.errors);
              }
            }
          });
      } else {
        // Password is too weak, display an error message or take appropriate action
        // You can set an error message or display it to the user.
      }
    } else {
      // Handle form validation errors
      // You can set validation error messages in your state or display them to the user.
    }
    setLoading(false);
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          handleSubmit(e);
        }}
        style={{
          minHeight: "480px",
        }}
        className="px-[10%] py-[6%] bg-white mx-auto rounded "
      >
        <h2 className="text-2xl font-bold text-center mb-[5%]">
          Register a new account
        </h2>
        <div className="flex justify-between gap-2 mb-[5%]">
          <input
            type="text"
            name="FirstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
            placeholder="First Name"
            className="inline-block border-solid border-[1px] border-[#eee] py-2 px-5 rounded w-[48%] placeholder:text-xs text-sm"
          />
          {errors.firstName && (
            <p className="error text-red-400">{errors.firstName}</p>
          )}

          <input
            type="text"
            name="LastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
            placeholder="Last Name"
            className="inline-block border-solid border-[1px] border-[#eee] py-2 px-5 rounded w-[48%] placeholder:text-xs text-sm"
          />
          {errors.lastName && <p className="text-red-400">{errors.lastName}</p>}
        </div>
        <div>
          <input
            type="email"
            name="email"
            value={email}
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
            required
            className="w-full inline-block border-solid border-[1px] border-[#eee] py-2 px-5 rounded placeholder:text-xs text-sm mb-[5%]"
          />
        </div>
        {errors.email && <p className="text-red-400">{errors.email}</p>}

        <div>
          <input
            type="text"
            name="telephone"
            value={telephone}
            placeholder="Phone Number"
            onChange={(e) => setTelephone(e.target.value)}
            required
            className="w-full inline-block border-solid border-[1px] border-[#eee] py-2 px-5 rounded placeholder:text-xs text-sm mb-[5%]"
          />
        </div>

        <div className="">
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              onClick={() => setPasswordClicked(true)}
              className={`w-full inline-block border-solid border-[1px] border-[#eee] py-2 px-5 rounded placeholder:text-xs text-sm
							${
                (validatePasswordStrength(password) === "weak" ||
                  password.length < 8) &&
                passwordClicked /* Only show the message when passwordClicked is true */
                  ? "text-red-400"
                  : "text-black"
              }`}
            />
            <span className="absolute top-1/2 right-4 transform -translate-y-1/2">
              {showPassword ? (
                <PiEyeSlashLight onClick={togglePasswordVisibility} />
              ) : (
                <PiEyeLight onClick={togglePasswordVisibility} />
              )}
            </span>
          </div>
          <p
            className={
              (password.length < 8 ||
                validatePasswordStrength(password) === "weak") &&
              passwordClicked
                ? "text-red-400 text-sm"
                : "hidden"
            }
          >
            {password.length < 8
              ? "Password is too short"
              : validatePasswordStrength(password) === "weak"
              ? "Password is too weak"
              : ""}
          </p>

          {errors.password && (
            <p className="text-sm text-red-400">{errors.password}</p>
          )}
        </div>

        <div className="flex items-center mt-[4%]">
          <input
            type="checkbox"
            id="agree"
            className="w-3 h-3 text-indigo-600 transition duration-150 ease-in-out form-checkbox"
            checked={agreed}
            onChange={(e) => setAgreed(e.target.checked)}
          />
          <label htmlFor="agree" className="block ml-2 text-sm">
            I agree to the terms and conditions
          </label>
        </div>

        {errors.agreed && (
          <p className="mt-2 text-sm text-red-400">{errors.agreed}</p>
        )}

        <button
          type="submit"
          className="w-full py-2 px-5 rounded text-sm bg-primary-color hover:bg-[#884c34] delay-0 duration-150 transition-colors ease-in text-white font-medium mt-[5%]"
        >
          {loading ? <CircularProgress /> : "REGISTER"}
        </button>

        <p className="mt-[2%] text-sm text-center font-medium ">
          Already have an account?{" "}
          <span
            className="text-primary-color cursor-pointer"
            onClick={switchToLogin}
          >
            Login Now
          </span>
          .
        </p>
      </form>
    </>
  );
}
