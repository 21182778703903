import axios from "axios";
import { toast } from "react-toastify";
import client, { testclient } from "../../api/client";
export const fetchUserLocation = async (dispatch) => {
  try {
    const res = (await axios.get("https://ipinfo.io/json")).data;
    dispatch({
      type: "FETCHED_DATA",
      payload: res?.country,
    });
    return true;
  } catch (error) {
    console.log("error fetching user location", error?.response);
    return false;
  }
};
export const signupHandler = async (data, dispatch) => {
  dispatch({
    type: "LOADING",
  });
  try {
    const res = (await client.post("/auth/register", data)).data;

    if (res?.message === "validation error") {
      throw new Error("User already exists");
    }
    if (res) {
      toast.success(
        "Sign up successfully check your mail to verify your account"
      );
    }
    // localStorage.setItem("nd-rest-tkn", res.data.data?.token);
    // localStorage.setItem("userData", JSON.stringify(res.data.data?.user));
    // // localStorage.removeItem("setupStore");
    // client.defaults.headers.post["Content-Type"] = "application/json";
    // client.defaults.headers.Authorization = `Bearer ${res.data.data?.token}`;

    return true;
  } catch (error) {
    error?.response?.data?.errors[error?.response?.data?.errors]?.map((cur) => {
      return toast.error(cur);
    });

    toast.error(error?.response?.data?.message || error?.message);
    dispatch({
      type: "ERROR",
      payload: error?.response?.data?.message,
    });

    return false;
  }
};

export const forgottenpassword = async (data, dispatch) => {
  // return alert(data.otp, data.email);
  // console.log("data", data);
  dispatch({
    type: "LOADING",
  });
  try {
    const res = await client.post("/api/v1/Auth/forgotPassword/", data);

    return true;
  } catch (error) {
    console.log("Error", error.response);
    alert(error?.response?.data?.message);
    dispatch({
      type: "ERROR",
      payload: error?.response?.data?.message,
    });

    return false;
  }
};
export const resendPassword = async (data, dispatch) => {
  // return alert(data.otp, data.email);
  // console.log("data", data);
  dispatch({
    type: "LOADING",
  });
  try {
    const res = await client.post("/api/v1/Auth/resetPassword", data);

    return true;
  } catch (error) {
    console.log("Error", error.response);
    dispatch({
      type: "ERROR",
      payload: error?.response?.data?.message,
    });

    return false;
  }
};
export const verifyOTP = async (data, dispatch) => {
  // return alert(data.otp, data.email);

  dispatch({
    type: "LOADING",
  });
  try {
    const res = await client.post(
      "/api/v1/Auth/verifyOtpCodePasswordReset",
      data
    );
    // localStorage.setItem("nd-rest-tkn", res.data.data?.token);
    // client.defaults.headers.post["Content-Type"] = "application/json";
    // client.defaults.headers.Authorization = `Bearer ${res.data.data?.token}`;
    // console.log("success", res.data);
    // dispatch({
    //   type: "SUCCESS",
    //   payload: res.data.data,
    // });
    return true;
  } catch (error) {
    console.log("Error", error.response);
    dispatch({
      type: "ERROR",
      payload: error?.response?.data?.message,
    });

    return false;
  }
};

export const signInHandler = async (data, dispatch) => {
  dispatch({
    type: "LOADING",
  });
  try {
    const res = (await client.post("/auth/login", data)).data;

    localStorage.setItem("nd-rest-tkn", res.token);
    client.defaults.headers.post["Content-Type"] = "application/json";
    client.defaults.headers.Authorization = `Bearer ${res.token}`;
    toast.success("Login successful!");
    dispatch({
      type: "SUCCESS",
      payload: {
        token: res.token,
      },
    });
    getCurrentUser(dispatch);
    return true;
  } catch (error) {
    toast.error(error?.response?.data?.message);

    console.log("Error", error.response || error);

    dispatch({
      type: "ERROR",
      payload: error?.response?.data?.message,
    });
    return false;
  }
};
export const googleHandler = async (data, dispatch) => {
  dispatch({
    type: "LOADING",
  });
  try {
    const res = (await client.post("/auth/google", data)).data;

    localStorage.setItem("nd-rest-tkn", res.token);
    client.defaults.headers.post["Content-Type"] = "application/json";
    client.defaults.headers.Authorization = `Bearer ${res.token}`;
    toast.success("Login successful!");
    dispatch({
      type: "SUCCESS",
      payload: {
        token: res.token,
      },
    });
    getCurrentUser(dispatch);
    return true;
  } catch (error) {
    toast.error(error?.response?.data?.message);

    console.log("Error", error.response || error);

    dispatch({
      type: "ERROR",
      payload: error?.response?.data?.message,
    });
    return false;
  }
};

export const UpdateUserInfo = async (data, dispatch) => {
  try {
    const res = await client.post("/user/info/update", data);

    // localStorage.setItem("nd-rest-tkn", res.data.data?.token);
    // localStorage.setItem("userData", JSON.stringify(res.data.data));
    // dispatch({
    //   type: "SUCCESS",
    //   payload: res.data.data,
    // });
    getCurrentUser(dispatch);
    return true;
  } catch (error) {
    toast.error(error?.response?.data?.message || "something went wrong");
    dispatch({
      type: "ERROR",
      payload: error?.response?.data?.message,
    });
    return false;
  }
};
export const UpdateUserPassword = async (data, dispatch) => {
  try {
    const res = await client.post("/user/password/update", data);

    getCurrentUser(dispatch);
    return true;
  } catch (error) {
    toast.error(error?.response?.data?.message);
    dispatch({
      type: "ERROR",
      payload: error?.response?.data?.message,
    });
    return false;
  }
};

export const logoutHandler = (dispatch) => {
  dispatch({
    type: "LOGOUT",
  });
  localStorage.clear();
  return true;
};

export const getCurrentUser = async (dispatch) => {
  const user = localStorage.getItem("userData");

  const token = localStorage.getItem("nd-rest-tkn");

  client.defaults.headers.post["Content-Type"] = "application/json";
  client.defaults.headers.Authorization = `Bearer ${token}`;

  if (user || token) {
    dispatch({
      type: "SUCCESS",
      payload: { user: JSON.parse(user), token: token },
    });
    try {
      const res = (await client.get(`/user`)).data;
      localStorage.setItem("userData", JSON.stringify(res));
      const payload = {
        user: res,
        token: token,
      };

      dispatch({
        type: "SUCCESS",
        payload: payload,
      });
      return true;
    } catch (error) {
      logoutHandler(dispatch);
      //   logoutHandler(dispatch);
      console.log("Couldn't get user", error);
    }
    return false;
  } else {
    logoutHandler(dispatch);
    return false;
  }
};
