import React from "react";
import VonOnlineCourseDetails from "../components/OnlineCourseDetail/VonOnlineCourseDetails";
import Description from "../components/LiveCourseDetail/Description";
import MainLayouts from "../layout/MainLayouts";

// import Instructor from "../components/OnlineCourseDetail/Instructor";
// import Grow from "../components/OnlineCourseDetail/Grow";

const OnlineCourseDetailPage = () => {
  return (
    <MainLayouts>
      <div className="mt-[5%] lg:mt-[5%]">
        <VonOnlineCourseDetails />
        {/* <Description /> */}
        {/* <Learn /> */}
        {/* <Benefit /> */}
        {/* <Instructor /> */}
        {/* <Reviews /> */}
        {/* <Grow /> */}
        {/* <Course /> */}
      </div>
    </MainLayouts>
  );
};
export default OnlineCourseDetailPage;
