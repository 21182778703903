import { Grid } from "@mui/material";
import { Typewriter } from "react-simple-typewriter";
import CourseIcon from "../../../assets/icons/courseicon.png";
import StudentIcon from "../../../assets/icons/studenticon.png";
import teacherIcon from "../../../assets/icons/tutorIcon.png";
import Animator from "../../../components/Aminator/Animator";
import { useIsMobile } from "../../../components/hooks/useIsMobile";
function ChooseUs() {
  const chooseus = [
    {
      icon: StudentIcon,
      title: "Flexible Learning",
      value:
        "Study on your terms with self-paced courses, allowing you to learn when it suits you best",
    },
    {
      icon: teacherIcon,
      title: "Expert Instructors",
      value:
        "Learn from professionals and experts in their respective fields, ensuring high-quality education",
    },
    {
      icon: CourseIcon,
      title: "Live Classes",
      value:
        "Learn from the comfort of your living room. Learning has never been much easier",
    },
    {
      icon: CourseIcon,
      title: "Verified Certificates",
      value:
        "Study on your terms with self-paced courses, allowing you to learn when it suits you best",
    },
    {
      icon: CourseIcon,
      title: "Live Time Access",
      value:
        "Learn from professionals and experts in their respective fields, ensuring high-quality education",
    },
    {
      icon: CourseIcon,
      title: "Interactive Learning",
      value:
        "Learn from the comfort of your living room. Learning has never been much easier",
    },
  ];
  const mobile = useIsMobile();
  return (
    <div
      className=" flex   pt-[17%] md:pt-[4.1%]  bg-[#f7f7f7] px-0 md:px-[7%]   pb-[17%]  md:pb-[4.1%]  "
      style={{
        minHeight: "90vh",
        overflowX: "hidden",
        flexDirection: "column",
      }}
    >
      <div className="innersection">
        <div className="flex justify-center items-center ">
          <h1
            style={{
              fontSize: mobile ? 25 : 30,
              fontWeight: 600,
              minHeight: mobile ? 40 : 70,
            }}
          >
            <Typewriter
              loop
              words={[
                "Why Choose Us",
                "Advantages",
                "Our Promise",
                "What Sets Us Apart",
                "Why We're Different",
                "Our Edge",
                "Key Benefits",
                "Our Strengths",
                "Why Select Us",
              ]}
            />
          </h1>
        </div>
        <div className="mt-14">
          <Grid container spacing={mobile ? 2 : 6}>
            {chooseus?.map((cur, i) => (
              <Grid item xs={12} md={4} key={i}>
                <Animator
                  style={{}}
                  delay={
                    i === 0
                      ? 200
                      : i === 1
                      ? 300
                      : i === 2
                      ? 400
                      : i === 3
                      ? 500
                      : 1 === 4
                      ? 600
                      : 700
                  }
                  variant="grow"
                  timeout={500}
                >
                  <div
                    className="flex items-center p-7 shadow-md bg-white  rounded-xl"
                    style={{
                      minHeight: 300,
                      flexDirection: "column",
                      textAlign: "center",
                      gap: 27,
                    }}
                  >
                    <img
                      src={cur.icon}
                      style={{
                        height: 80,
                        width: 80,
                      }}
                    />
                    <h1
                      style={{
                        fontSize: 22,
                        fontWeight: 600,
                      }}
                    >
                      {cur.title}
                    </h1>
                    <p
                      style={{
                        color: "#aaa",
                        fontWeight: 200,
                      }}
                    >
                      {cur.value}
                    </p>
                  </div>
                </Animator>
              </Grid>
            ))}{" "}
          </Grid>
        </div>
      </div>
    </div>
  );
}
export default ChooseUs;
