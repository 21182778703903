import {
  PiEnvelopeLight,
  PiMapPinLineLight,
  PiPhoneCallLight,
} from "react-icons/pi";
import { Link } from "react-router-dom";

export default function ContactTop() {
  return (
    <div className="innersection">
      <section className="mt-[15vh] sm:mt-[20vh] md:mt-[30vh]  flex flex-col md:flex-row gap-[3%] justify-between md:flex-wrap mb-[15%] sm:mb-[5%]">
        <div className="flex w-full min-[480px]:w-[80%] sm:w-1/2 mx-auto md:mx-0 md:w-[31%] flex-col items-center text-center shadow-[0px_0px_10px_-5px_rgba(0,0,0,0.3)] px-[5%] py-[6%] rounded-lg border-b-solid border-b-[#0352E600] border-b-4 hover:border-b-[#0352E6] transition-colors delay-0 duration-150 ease-out mb-[5%] md:mb-0">
          <PiMapPinLineLight className="text-[#0352E6] text-6xl" />
          <h4 className="text-xl mb-4 mt-3 font-bold uppercase">Address</h4>
          <p className="text-sm">
            <span className="font-semibold">US Office:</span> 1519, Bethlehem
            Avenue, Dundalk, MD
          </p>
          <p className="text-sm">
            <span className="font-semibold">Lagos Office:</span> 55, Coker Road,
            Off Town Planning Way, Ilupeju
          </p>
        </div>
        <div className="flex w-full min-[480px]:w-[80%] sm:w-1/2 mx-auto md:mx-0 md:w-[31%] flex-col items-center text-center px-[5%] py-[6%] shadow-[0px_0px_10px_-5px_rgba(0,0,0,0.3)] rounded-lg border-b-solid border-b-[#5EBB3E00] border-b-4 hover:border-b-[#5EBB3E] transition-colors delay-0 duration-150 ease-out mb-[5%] md:mb-0">
          <PiPhoneCallLight className="text-[#5EBB3E] text-6xl" />
          <h4 className="text-xl mb-4 mt-3 font-bold uppercase">
            Phone Number
          </h4>
          <Link to="tel:+14433321473" className="text-sm">
            +1 (240) 615-1548
          </Link>
        </div>
        <div className="flex w-full min-[480px]:w-[80%] sm:w-1/2 mx-auto md:mx-0 md:w-[31%] flex-col items-center text-center px-[5%] py-[6%] shadow-[0px_0px_10px_-5px_rgba(0,0,0,0.3)] rounded-lg border-b-solid border-b-[#FFB60600] border-b-4 hover:border-b-[#FFB606] transition-colors delay-0 duration-150 ease-out mb-[5%] md:mb-0">
          <PiEnvelopeLight className="text-[#FFB606] text-6xl" />
          <h4 className="text-xl mb-4 mt-3 font-bold uppercase">Email</h4>
          <Link to="mailto:info@e1xacademy.net" className="text-sm">
            info@e1xacademy.net
          </Link>
        </div>
      </section>
    </div>
  );
}
