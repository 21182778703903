"use client"

import { useState } from "react"
import {
    Box,
    Button,
    Card,
    CardContent,
    CardHeader,
    Container,
    Divider,
    Grid,
    TextField,
    Typography,
    CircularProgress,
    FormControl,
    Autocomplete,
} from "@mui/material"
import axios from "axios";
import Animator from "../../components/Aminator/Animator";
import EventNoteIcon from "@mui/icons-material/EventNote"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import Webinar from "../../assets/images/Webinar2.jpg";
import MainLayouts from "../../layout/MainLayouts";
import { countryCodes } from "../../utility";
import WebinarDiscount from "./WebinarDiscount";

// Create a theme instance
const theme = createTheme({
    palette: {
        primary: {
            main: "#d15242",
        },
        secondary: {
            main: "#dc004e",
        },
    },
    typography: {
        fontFamily: "Montserrat, sans-serif",
    },
})

export default function WebinarForm() {
    const [formData, setFormData] = useState({
        firstname: "",
        lastname: "",
        email: "",
        phoneCode: "+234",
        phone: "",
        location: "",
        occupation: "",
        referred_by: "",
        payload: "",
    })

    const [errors, setErrors] = useState({
        firstname: "",
        lastname: "",
        phoneCode: "",
        phone: "",
        email: "",
        location: "",
        occupation: "",
        referred_by: "",
    })

    const [isSubmitting, setIsSubmitting] = useState(false)
    const [submitSuccess, setSubmitSuccess] = useState(false)

    const handleChange = (e) => {
        const { name, value } = e.target
        setFormData({
            ...formData,
            [name]: value,
        })


        if (errors[name]) {
            setErrors({
                ...errors,
                [name]: "",
            })
        }
    }

    const validateForm = () => {
        let valid = true
        const newErrors = {
            firstname: "",
            lastname: "",
            email: "",
            phoneCode: "",
            phone: "",
            location: "",
            occupation: "",
            referred_by: "",
        }

        if (formData.firstname.trim().length < 2) {
            newErrors.firstname = "First name must be at least 2 characters"
            valid = false
        }

        if (formData.lastname.trim().length < 2) {
            newErrors.lastname = "Last name must be at least 2 characters"
            valid = false
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
        if (!emailRegex.test(formData.email)) {
            newErrors.email = "Please enter a valid email address"
            valid = false
        }

        if (!formData.phoneCode) {
            newErrors.phoneCode = "Please select a country code"
            valid = false
        }

        if (formData.phone.trim().length < 7) {
            newErrors.location = "Phone number must be at least 7 characters"
            valid = false
        }

        if (formData.location.trim().length < 2) {
            newErrors.location = "Location must be at least 2 characters"
            valid = false
        }
        if (formData.occupation.trim().length < 2) {
            newErrors.location = "Occupation must be at least 2 characters"
            valid = false
        }
        // if (formData.referred_by.trim().length < 2) {
        //     newErrors.location = "Referral must be at least 2 characters"
        //     valid = false
        // }

        setErrors(newErrors)
        return valid
    }

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (!validateForm()) {
            return
        }

        setIsSubmitting(true)

        try {

            const response = await axios.post("https://e1xaca.edoubleone.net/api/webinar/register", formData, {
                headers: { "Content-Type": "application/json" }
            });
            console.log("Registration successful:", response.data);

            setSubmitSuccess(true)


            setTimeout(() => {
                setFormData({
                    firstname: "",
                    lastname: "",
                    email: "",
                    location: "",
                    phoneCode: "+234",
                    phone: "",
                    occupation: "",
                    referred_by: "",
                    payload: "",
                })
                setSubmitSuccess(false)
            }, 3000)
        } catch (error) {
            if (error.response) {
                console.error("Error 422 Details:", error.response.data);
            }
        } finally {
            setIsSubmitting(false)
        }
    }

    return (
        <MainLayouts>
            <ThemeProvider theme={theme}>
                <div
                    className=" flex  pt-[27%] md:pt-[9.1%]  bg-[#f7f7f7] px-[0%] md:px-[7%]   pb-[27%]  md:pb-[9.1%]   "
                    style={{
                        // minHeight: "90vh",
                        overflowX: "hidden",
                        flexDirection: "column",
                        justifyContent: "center",
                        gap: 30,
                    }}
                >
                    <div className="innersection">
                        <Grid container alignItems={"center"} color={"#f7f7f7"} backgroundColor={"#f7f7f7"} >
                            <Grid item xs={12} md={6}>
                                <Animator direction="right"
                                    delay={500}
                                    timeout={500}
                                    variant="slide">
                                    <img
                                        src={Webinar}
                                        alt="Webinar"
                                        style={{ width: "100%", borderRadius: "20px" }}

                                    />
                                </Animator>
                            </Grid>
                            <Grid item xs={12} md={6} backgroundColor={"#f7f7f7"}>
                                <Animator
                                    style={{}}
                                    delay={300}
                                    timeout={300}
                                    variant="zoom">
                                    <Container maxWidth="md" sx={{ py: 4, fontFamily: "Monserrat" }} >
                                        <Card elevation={0} sx={{ backgroundColor: "#f7f7f7" }} >
                                            <CardHeader
                                                title={
                                                    <Box display="flex" alignItems="center" gap={2} sx={{ pb: 2 }}>
                                                        <EventNoteIcon color="primary" />
                                                        <Typography variant="h5" component="h1">
                                                            Webinar Registration
                                                        </Typography>
                                                    </Box>
                                                }
                                                subheader="Fill out the form below to register for our upcoming webinar"
                                            />
                                            <Divider />
                                            <CardContent>
                                                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12} sm={6}>
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                id="firstName"
                                                                label="First Name"
                                                                name="firstname"
                                                                autoComplete="given-name"
                                                                value={formData.firstname}
                                                                onChange={handleChange}
                                                                error={!!errors.firstname}
                                                                helperText={errors.firstname}
                                                                margin="normal"

                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                id="lastName"
                                                                label="Last Name"
                                                                name="lastname"
                                                                autoComplete="family-name"
                                                                value={formData.lastname}
                                                                onChange={handleChange}
                                                                error={!!errors.lastname}
                                                                helperText={errors.lastname}
                                                                margin="normal"


                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                id="email"
                                                                label="Email Address"
                                                                name="email"
                                                                autoComplete="email"
                                                                type="email"
                                                                value={formData.email}
                                                                onChange={handleChange}
                                                                error={!!errors.email}
                                                                helperText={errors.email}
                                                                margin="normal"

                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Grid container spacing={1}>
                                                                <Grid item xs={4} sm={3}>
                                                                    <FormControl fullWidth margin="normal" error={!!errors.phoneCode}>
                                                                        <Autocomplete
                                                                            id="phoneCode"
                                                                            options={countryCodes}
                                                                            autoHighlight
                                                                            disableClearable={true}
                                                                            value={countryCodes.find((option) => option.value === formData.phoneCode) || null}
                                                                            onChange={(event, newValue) => {
                                                                                setFormData({
                                                                                    ...formData,
                                                                                    phoneCode: newValue ? newValue.value : "+234",
                                                                                })
                                                                                if (errors.phoneCode) {
                                                                                    setErrors({
                                                                                        ...errors,
                                                                                        phoneCode: "",
                                                                                    })
                                                                                }
                                                                            }}
                                                                            getOptionLabel={(option) => `${option.value}`}
                                                                            renderOption={(props, option) => (
                                                                                <Box component="li" {...props}>
                                                                                    {option.value}
                                                                                </Box>
                                                                            )}
                                                                            renderInput={(params) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                    label="Code"
                                                                                    error={!!errors.phoneCode}
                                                                                    helperText={errors.phoneCode}
                                                                                    inputProps={{
                                                                                        ...params.inputProps,
                                                                                        autoComplete: "new-password", // disable autocomplete and autofill
                                                                                    }}
                                                                                />
                                                                            )}
                                                                        />
                                                                    </FormControl>
                                                                </Grid>
                                                                <Grid item xs={8} sm={9}>
                                                                    <TextField
                                                                        required
                                                                        fullWidth
                                                                        disable
                                                                        id="phone"
                                                                        label="Phone Number"
                                                                        name="phone"
                                                                        autoComplete="tel"
                                                                        type="tel"
                                                                        value={formData.phone}
                                                                        onChange={handleChange}
                                                                        error={!!errors.phone}
                                                                        helperText={errors.phone}
                                                                        margin="normal"
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                id="location"
                                                                label="Location"
                                                                name="location"
                                                                placeholder="City, Country"
                                                                value={formData.location}
                                                                onChange={handleChange}
                                                                error={!!errors.location}
                                                                helperText={errors.location}
                                                                margin="normal"

                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                id="occupation"
                                                                label="Occupation"
                                                                name="occupation"
                                                                value={formData.occupation}
                                                                onChange={handleChange}
                                                                error={!!errors.occupation}
                                                                helperText={errors.occupation}
                                                                margin="normal"

                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField

                                                                fullWidth
                                                                id="referred_by"
                                                                label="Referred_by"
                                                                name="referred_by"
                                                                value={formData.referred_by}
                                                                onChange={handleChange}
                                                                error={!!errors.referred_by}
                                                                helperText={errors.referred_by}
                                                                margin="normal"

                                                            />
                                                        </Grid>

                                                        <Grid item xs={12}>
                                                            <Button
                                                                type="submit"
                                                                fullWidth
                                                                variant="contained"
                                                                color="primary"
                                                                size="large"
                                                                disabled={isSubmitting}
                                                                sx={{ mt: 2, mb: 2 }}
                                                            >
                                                                {isSubmitting ? (
                                                                    <CircularProgress size={24} color="inherit" />
                                                                ) : submitSuccess ? (
                                                                    "Registration Successful!"
                                                                ) : (
                                                                    "Register Now"
                                                                )}
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                    {submitSuccess && (
                                                        <Box sx={{ mt: 2, textAlign: "center" }}>
                                                            <Typography color="success.main">
                                                                You have been registered successfully! Check your email for confirmation.
                                                            </Typography>
                                                        </Box>
                                                    )}
                                                    <Box sx={{ mt: 2, textAlign: "center" }}>
                                                        <Typography variant="body2" color="text.secondary">
                                                            You'll receive a confirmation email with webinar details.
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </CardContent>
                                        </Card>
                                    </Container>
                                </Animator>
                            </Grid>
                        </Grid>
                    </div>
                </div>
                <WebinarDiscount />
            </ThemeProvider>
        </MainLayouts>
    )
}

