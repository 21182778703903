import { keyframes } from "@mui/material";
import RightImage from "../../../assets/images/headerbg.png";
import Image from "../../../assets/images/landingVector.png";
import Animator from "../../../components/Aminator/Animator";
import { useIsMobile } from "../../../components/hooks/useIsMobile";

function Header({ navigate }) {
  const images = [RightImage, RightImage /*, more images */];
  const mobile = useIsMobile();
  const bounceAnimation = keyframes`
  0%, 100% {
    transform: bottom: "30%",;
  }
  50% {
    transform: bottom: "50%",;
  }
`;

  return (
    <div
      className="flex pt-[27%] md:pt-[9.1%]  bg-[#F1F1F1]  pb-[27%]  md:pb-[9.1%] "
      style={{
        minHeight: mobile ? "80vh" : "",
        // minHeight: "80vh",
        // maxHeight: "90vh",
        justifyContent: "center",
        position: "relative",
        display: "flex",
        overflowX: "hidden",
      }}
    >
      <div
        className="innersection"
        style={{
          justifyContent: "center",
          position: "relative",
          display: "flex",
          width: "100%",
        }}
      >
        <div
          className="flex items-center justify-between w-[100%] mt-6 "
          style={
            {
              // maxWidth: "80vw",
            }
          }
        >
          <div
            className="w-[100%] md:w-[55%] relative  "
            style={{
              zIndex: 1,
            }}
          >
            <Animator
              style={{}}
              // direction="x"
              delay={300}
              timeout={300}
              variant="zoom"
            >
              <h1
                style={{
                  fontSize: mobile ? 40 : 45,
                  fontWeight: 600,
                }}
              >
                Master Tech Skills with Ease!
              </h1>
            </Animator>
            <Animator
              style={{}}
              // direction="x"
              delay={400}
              timeout={400}
              variant="fade"
            >
              <p className="text-[#aaa] ">
                Unlock Tech Mastery Effortlessly! Explore and enhance your
                skills with our user-friendly platform.
              </p>
            </Animator>
            <Animator
              style={{}}
              // direction="x"
              delay={500}
              timeout={500}
              variant="fade"
            >
              <div className="flex gap-2 items-center mt-6">
                <button
                  onClick={() => {
                    navigate("/sign-up");
                  }}
                  className="h-5 bg-[#d15242] text-white py-6 px-6 md:px-12 rounded-md  text-xs flex items-center justify-center  hover:bg-white hover:text-black"
                >
                  Get Started
                </button>
                <button
                  onClick={() => {
                    navigate("/courses/categories");
                  }}
                  style={{
                    borderColor: "#d15242",
                    borderWidth: 1,
                    color: "#000",
                  }}
                  className="h-5  text-white py-6 px-6 md:px-10  rounded-md  text-xs flex items-center justify-center  hover:bg-white hover:text-black"
                >
                  See All Courses
                </button>
              </div>
            </Animator>
          </div>
          <div className="hidden  w-[40%] justify-end md:flex ">
            <Animator
              style={{}}
              direction="left"
              delay={300}
              timeout={300}
              variant="slide"
            >
              <div
                style={{
                  zIndex: 1,
                  position: "absolute",
                  left: "60%",
                  right: "40%",
                  padding: 13,
                  minWidth: "16%",
                  borderRadius: 20,
                  bottom: "50%",
                  background: "#fff9",
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  animation: `${bounceAnimation} 2s infinite`,
                }}
              >
                <img
                  src={RightImage}
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: 20,
                  }}
                />
                <div>
                  <h1
                    style={{
                      fontSize: 15,
                      fontWeight: 600,
                    }}
                  >
                    300+
                  </h1>
                  <p
                    style={{
                      fontSize: 10,
                    }}
                  >
                    Registered Student
                  </p>
                </div>
              </div>
            </Animator>
            <Animator
              style={{}}
              direction="left"
              delay={400}
              timeout={400}
              variant="slide"
            >
              <div
                style={{
                  position: "absolute",
                  left: "65%",
                  zIndex: 1,
                  right: "30%",
                  padding: 13,
                  minWidth: "16%",
                  borderRadius: 20,
                  bottom: "35%",
                  background: "#fff9",
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  animation: `${bounceAnimation} 2s infinite`,
                }}
              >
                <img
                  src={RightImage}
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: 20,
                  }}
                />
                <div>
                  <h1
                    style={{
                      fontSize: 15,
                      fontWeight: 600,
                    }}
                  >
                    400+
                  </h1>
                  <p
                    style={{
                      fontSize: 10,
                    }}
                  >
                    Certified Student
                  </p>
                </div>
              </div>
            </Animator>
            <Animator
              style={{}}
              direction="left"
              delay={500}
              timeout={500}
              variant="slide"
            >
              <div
                style={{
                  position: "absolute",
                  left: "70%",
                  right: "30%",
                  padding: 13,
                  minWidth: "16%",
                  borderRadius: 20,
                  bottom: "20%",
                  zIndex: 1,
                  background: "#fff9",
                  display: "flex",
                  alignItems: "center",
                  gap: 10,
                  animation: `${bounceAnimation} 2s infinite`,
                }}
              >
                <img
                  src={RightImage}
                  style={{
                    width: 40,
                    height: 40,
                    borderRadius: 20,
                  }}
                />
                <div>
                  <h1
                    style={{
                      fontSize: 15,
                      fontWeight: 600,
                    }}
                  >
                    500+
                  </h1>
                  <p
                    style={{
                      fontSize: 10,
                    }}
                  >
                    Instructors
                  </p>
                </div>
              </div>
            </Animator>
            <Animator
              style={{}}
              // direction="x"
              delay={300}
              timeout={300}
              variant="zoom"
            >
              <img
                className="bouncing-image"
                src={RightImage}
                style={{
                  position: "relative",
                  height: "65vh",
                  // width: "100%",
                  objectFit: "contain",
                }}
              />
            </Animator>
          </div>
        </div>
      </div>
      <div
        style={{
          position: "absolute",
          bottom: 0,
          left: 0,
        }}
      >
        <img
          src={Image}
          style={{
            height: 400,
            width: 400,
            objectFit: "cover",
          }}
        />
      </div>
    </div>
  );
}
export default Header;
