import React from "react";
import { isValidDate } from "../../utility";

export default function CousesOutline({
  title,
  date,
  program_targets,
  onClick = () => {},
  image,
  course_link,
}) {
  const isDateValid = isValidDate(date);

  return (
    <div
      style={{
        minHeight: 100,
        padding: 15,
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap",
        gap: 20,
        justifyContent: "space-between",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <img
          style={{
            height: 90,
            width: 90,
            objectFit: "cover",
          }}
          src={image}
        />
        <div style={{ marginLeft: 20 }}>
          <p
            style={{
              fontWeight: 500,
              fontSize: 20,
            }}
          >
            {title}
          </p>
          <div
            dangerouslySetInnerHTML={{
              __html: program_targets?.slice(0, 20),
            }}
          />
          <p> Date: {date}</p>
        </div>
      </div>
      <button
        style={{
          color: isDateValid ? "#fff" : "#000",
          backgroundColor: isDateValid ? "blue" : "grey",
        }}
        onClick={() => {
          let url = course_link;
          if (url && !/^https?:\/\//i.test(url)) {
            url = "https://" + url; // Add 'https://' if missing
          }
          window.open(url, "_blank");

          onClick();
        }}
        disabled={!isDateValid}
        type="button"
        className={`text-white bg-${
          isDateValid ? "blue" : "grey"
        }-700 hover:bg-${
          isDateValid ? "blue" : "grey"
        }-800 focus:ring-4 focus:ring-${
          isDateValid ? "blue" : "grey"
        }-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-${
          isDateValid ? "blue" : "grey"
        }-600 dark:hover:bg-${
          isDateValid ? "blue" : "grey"
        }-700 dark:disabled:bg-${
          isDateValid ? "blue" : "grey"
        }-400 dark:focus:ring-${
          isDateValid ? "blue" : "grey"
        }-800 dark:disabled:text-gray-400 dark:disabled:cursor-not-allowed focus:outline-none dark:focus:ring-${
          isDateValid ? "blue" : "grey"
        }-800`}
      >
        {isDateValid ? "Attend Program" : "Not Available"}
      </button>
    </div>
  );
}
