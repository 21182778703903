import {
  PiGlobeStandLight,
  PiStudentLight,
  PiCertificateLight,
} from "react-icons/pi";
import { GiTeacher } from "react-icons/gi";

export default function AboutTop() {
  return (
    <section className="mt-[15vh] sm:mt-[20vh] md:mt-[30vh] mb-[5%] ">
      <div className="text-center w-[75%] sm:w-[55%] mx-auto mb-[5%]">
        <h2 className="font-bold text-[28px] leading-[32px] mb-[1.5%]">
          Turn your ambition into a success story
        </h2>
        <p>
          Dedicated to empowering individuals with the knowledge and skills they
          need to succeed in today's rapidly evolving world.{" "}
        </p>
      </div>

      <div className=" flex flex-col sm:flex-row gap-[1%] justify-between sm:flex-wrap mb-[15%] sm:mb-[5%]">
        <div className="flex w-full min-[480px]:w-[80%] sm:w-[47%] mx-auto md:mx-0 md:w-[23%] flex-col items-center text-center shadow-[0px_0px_10px_-5px_rgba(0,0,0,0.3)] px-[5%] py-[6%] rounded-lg border-b-solid border-b-[#82D16F00] border-b-4 hover:border-b-[#82D16F] transition-colors delay-0 duration-150 ease-out mb-[5%] md:mb-0 md:mt-[5%]">
          <PiGlobeStandLight className="text-[#82D16F] text-7xl" />
          <h4 className="text-4xl mb-4 mt-3 font-bold uppercase text-[#82D16F]">
            1050
          </h4>
          <p className="">Global Students</p>
        </div>
        <div className="flex w-full min-[480px]:w-[80%] sm:w-[47%] mx-auto md:mx-0 md:w-[23%] flex-col items-center text-center px-[5%] py-[6%] shadow-[0px_0px_10px_-5px_rgba(0,0,0,0.3)] rounded-lg border-b-solid border-b-[#4489F300] border-b-4 hover:border-b-[#4489F3] transition-colors delay-0 duration-150 ease-out mb-[5%] md:mb-0 h-fit">
          <GiTeacher className="text-[#4489F3] text-7xl" />
          <h4 className="text-4xl mb-4 mt-3 font-bold uppercase text-[#4489F3]">
            700
          </h4>
          <p className="">Classes Complete</p>
        </div>
        <div className="flex w-full min-[480px]:w-[80%] sm:w-[47%] mx-auto md:mx-0 md:w-[23%] flex-col items-center text-center px-[5%] py-[6%] shadow-[0px_0px_10px_-5px_rgba(0,0,0,0.3)] rounded-lg border-b-solid border-b-[#FFB60600] border-b-4 hover:border-b-[#FFB606] transition-colors delay-0 duration-150 ease-out mb-[5%] md:mb-0 h-fit">
          <PiStudentLight className="text-[#FFB606] text-7xl" />
          <h4 className="text-4xl mb-4 mt-3 font-bold uppercase text-[#FFB606]">
            100
          </h4>
          <p className="">Students Enrolled</p>
        </div>
        <div className="flex w-full min-[480px]:w-[80%] sm:w-[47%] mx-auto md:mx-0 md:w-[23%] flex-col items-center text-center px-[5%] py-[6%] shadow-[0px_0px_10px_-5px_rgba(0,0,0,0.3)] rounded-lg border-b-solid border-b-[#ED745900] border-b-4 hover:border-b-[#ED7459] transition-colors delay-0 duration-150 ease-out mb-[5%] md:mb-0 md:mt-[5%]">
          <PiCertificateLight className="text-[#ED7459] text-7xl" />
          <h4 className="text-4xl mb-4 mt-3 font-bold uppercase text-[#ED7459]">
            21
          </h4>
          <p className="">Certified Instructors</p>
        </div>
      </div>
    </section>
  );
}
