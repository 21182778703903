import { useLocation } from "react-router-dom";
import ScrollToTop from "react-scroll-to-top";
import VonFooter from "../components/AllVon/VonFooter/VonFooter";
import VonHeader from "../components/AllVon/VonHeader/VonHeader";

export default function MainLayouts({ children, showFooter = true }) {
  const location = useLocation();
  const isCourseVideoPath = location.pathname.startsWith("/course-video");
  return (
    <>
      <VonHeader />
      {children}
      {showFooter && (
        <ScrollToTop
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#d15424",
            color: "#fff",
          }}
          smooth
        />
      )}

      {showFooter && !isCourseVideoPath && <VonFooter />}
    </>
  );
}
