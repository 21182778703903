import React from "react";
import Image from "../../assets/images/Image.png";
import { useIsMobile } from "../hooks/useIsMobile";
export default function SponsorComment({
  first_name,
  email,
  last_name,

  phone,
  image,
  sponsorship_details,
  message,
}) {
  const mobile = useIsMobile();
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: mobile ? "column" : "row",
        // flexWrap: "wrap",

        gap: 80,
      }}
    >
      <div style={{ width: mobile ? "100%" : "40%" }}>
        <img
          style={{
            width: "100%",
            height: 400,
            backgroundColor: "#fff",
            borderRadius: 30,

            // height: "100%",
            objectFit: "cover",
          }}
          src={image ? image : Image}
        />
      </div>
      <div
        style={{
          width: mobile ? "100%" : "40%",
        }}
      >
        <h1
          style={{
            fontSize: 25,
            fontWeight: 500,
          }}
        >
          {first_name + " " + last_name}
        </h1>
        <p>Lagos</p>
        <p
          style={{
            marginTop: 20,
            lineBreak: "anywhere",
          }}
        >
          {message}
        </p>
      </div>
    </div>
  );
}
