import React, { useState } from 'react';
import Webinar from "../../assets/images/webinar_discount.jpg";
import axios from 'axios';
import { Box, Button, TextField, Grid } from '@mui/material';

const WebinarDiscount = () => {
    const [email, setEmail] = useState('');
    const [amount, setAmount] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [pricing, setPricing] = useState(null);
    const [error, setError] = useState(null);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        setError(null);

        try {
            const response = await axios.post(
                'https://e1xaca.edoubleone.net/api/webinar-discount',
                { email, amount }, // Now including amount in the request
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            const data = response.data;

            if (data.success && data.authorization_url) {
                setPricing({
                    amount: data.amount,
                    percentage: data.percentage || 50 // Default to 50% if not provided
                });

                // Redirect to Paystack
                window.location.href = data.authorization_url.url;
            } else {
                setError(data.message || 'Failed to get payment link. Please try again.');
            }
        } catch (err) {
            setError(err.response?.data?.message || 'Only registered webinar attendees can access this discount');
            console.error('Error:', err);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div className="flex px-[15px] md:px-[7%] py-8 items-center">
            <div className='flex flex-col md:flex-row gap-8 items-center'>
                <div className="md:w-1/2">
                    <img
                        src={Webinar}
                        alt="Webinar discount offer"
                        className="rounded-lg shadow-lg w-full px-2"
                    />
                </div>

                <div className="md:w-1/2">
                    <h3 className="text-3xl font-bold text-gray-800 mb-4">
                        Unlock a Special Discount for Our Exclusive Webinar!
                    </h3>

                    <p className="text-lg text-gray-600 mb-6">
                        Join E1X Academy's April weekend class and enjoy an exclusive {pricing?.percentage || 50}% discount on your enrollment!
                        This offer is available only for live attendees.
                    </p>

                    <ul className="space-y-3 mb-8">
                        <li className="flex items-start">
                            <span className="mr-2">✓</span>
                            <span className="text-gray-700">Flexible Payment Plan: Pay in up to three easy installments</span>
                        </li>
                        <li className="flex items-start">
                            <span className="mr-2 text-primary">✓</span>
                            <span className="text-gray-700">Limited Offer: Secure your spot NOW before it's too late!</span>
                        </li>
                    </ul>

                    <div className="bg-gray-50 p-6 rounded-lg">
                        <h4 className="text-xl font-semibold mb-4 text-gray-800">
                            Claim Your {pricing?.percentage || 50}% Discount Now!
                        </h4>

                        {error && (
                            <div className="mb-4 p-3 bg-red-100 text-red-700 rounded-md">
                                {error}
                            </div>
                        )}

                        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <TextField
                                        type="email"
                                        fullWidth
                                        value={email}
                                        required
                                        onChange={(e) => setEmail(e.target.value)}
                                        placeholder="Enter Your Email"
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={12} >
                                    <TextField
                                        type="number"
                                        fullWidth
                                        value={amount}
                                        required
                                        onChange={(e) => setAmount(e.target.value)}
                                        placeholder="Enter Amount"
                                        margin="normal"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Button
                                        type="submit"
                                        fullWidth
                                        disabled={isSubmitting}
                                        color="primary"
                                        size="large"
                                        variant="contained"
                                        sx={{ mt: 2, mb: 2 }}
                                    >
                                        {isSubmitting ? 'Processing...' : 'Get Discount'}
                                    </Button>
                                </Grid>
                            </Grid>
                            <p className="text-sm text-gray-500 mt-2">
                                By clicking "Get Discount", you'll be redirected to our secure payment page.
                            </p>
                        </Box>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WebinarDiscount;