import React from "react";
import Contact from "../components/Contact/Contact";
import ContactTop from "../components/Contact/ContactTop";
import MainLayouts from "../layout/MainLayouts";

function ContactPage() {
  return (
    <MainLayouts>
        <ContactTop />
        <Contact />
    </MainLayouts>
  );
}

export default ContactPage;
