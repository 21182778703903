import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { getDatabase, ref, remove } from "firebase/database";
import { app } from "../../firebase";

function DeleteDialog({ open, onClose, onClick, id, chatId }) {
  const deleteChat = async () => {
    // console.log("chats/" + id + "/" + chatId);
    const db = getDatabase(app);
    remove(ref(db, "chats/" + id + "/" + chatId))
      .then(() => {
        // Data saved successfully!
      })
      .catch((error) => {
        console.log(error);
        // The write failed...
      });
    onClose();
  };
  return (
    <Dialog
      onClose={onClose}
      open={open}
      // fullWidth
      PaperProps={{
        style: {
          minWidth: 500,
          // minHeight: 200,
        },
      }}
      sx={{
        // minWidth: 340,
        minHeight: 200,
      }}
    >
      <DialogTitle>
        <h1>Delete Message ??</h1>
      </DialogTitle>
      <DialogActions
        style={{
          marginTop: 50,
          marginBottom: 10,
          marginRight: 5,
        }}
      >
        <Button
          variant="outlined"
          color="warning"
          size="large"
          onClick={onClose}
        >
          <h1> Close</h1>
        </Button>
        <Button
          onClick={deleteChat}
          variant="contained"
          sx={{
            background: "#d15242",
            ":hover": {
              background: "red",
            },
          }}
          size="large"
        >
          <h1> Delete </h1>
        </Button>
      </DialogActions>
    </Dialog>
  );
}
export default DeleteDialog;
