import { Grid } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Typewriter } from "react-simple-typewriter";
import background from "../../assets/images/sponsorbg1.png";
import headerImage from "../../assets/images/sponsorheader2.png";
import Animator from "../../components/Aminator/Animator";
import { useIsMobile } from "../hooks/useIsMobile";
export default function SponsorHeader() {
  const mobile = useIsMobile();
  const navigate = useNavigate();
  return (
    <>
      <div
        className="mt-[15%] md:mt-[3.2%]"
        style={{
          position: "relative",
          minHeight: "100vh",
        }}
      >
        <div
          style={{
            background: `url(${background})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            minHeight: "90vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            color: "#fff",
          }}
        >
          <div
            className="innersection"
            style={{
              width: mobile ? "100%" : "40%",
            }}
          >
            <h1
              style={{
                fontWeight: 550,
                fontSize: 56,
                lineHeight: 1,
              }}
            >
              Become a sponsor
            </h1>
            <p
              style={{
                marginTop: 26,
                fontSize: 18,
              }}
            >
              Interested in the work we do? Join us in providing opportunities
              to deserving individuals. Invest in shaping a brighter future
              today.
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                onClick={() => {
                  navigate("/sponsor/participate");
                }}
                style={{
                  width: mobile ? "100%" : "40%",
                  height: 50,
                  background: "#d15242",
                  marginTop: 40,
                  borderRadius: 10,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#fff",
                }}
              >
                Join Us
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div
          className="innersection"
          style={{
            minHeight: "50vh",
          }}
        >
          <Grid container spacing={6}>
            <Grid item sm={12} md={6}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  color: "#000",
                }}
              >
                <h1
                  style={{
                    fontWeight: 550,
                    fontSize: 30,
                    textAlign: mobile ? "center" : "",
                  }}
                >
                  <Typewriter delaySpeed={100} words={["How it works"]} />
                </h1>
                <p
                  style={{
                    marginTop: 20,
                  }}
                >
                  Experience our 100% transparent contribution process.
                  Customise your impact by creating a scholarship in your name
                  or remaining anonymous. You dictate distribution criteria,
                  choose recipients, and even have the option to meet the
                  beneficiaries. It's all within your control. Start here
                </p>
                <button
                  onClick={() => {
                    navigate("/sponsor/participate");
                  }}
                  style={{
                    width: mobile ? "100%" : "40%",
                    height: 50,
                    background: "#d15242",
                    marginTop: 40,
                    borderRadius: 10,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "#fff",
                  }}
                >
                  Become a sponsor
                </button>
              </div>
            </Grid>
            <Grid item sm={12} md={6}>
              <Animator style={{}} timeout={600} delay={600} variant="zoom">
                <img
                  style={{
                    borderTopLeftRadius: 100,
                    borderBottomRightRadius: 100,
                    height: 400,
                    objectFit: "cover",
                    width: "100%",
                  }}
                  src={headerImage}
                />
              </Animator>
            </Grid>
          </Grid>
        </div>
      </div>

      <div
        style={{
          minHeight: "20vh",
          display: mobile ? "none" : "",
        }}
      ></div>
    </>
  );
}
