import { Typography } from "@mui/material";

function Discount({ value, border = false }) {
  return (
    <div
      style={{
        background: "#fff",
        color: "#000",
        height: 55,
        width: 55,
        display: "none",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        border: border ? "solid #aaa 1px" : "none",
      }}
    >
      <Typography
        fontWeight={700}
        style={{
          color: "#f90",
        }}
      >
        {"-" + value + "%"}
      </Typography>
    </div>
  );
}
export default Discount;
