import React, { useContext, useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import client from "../../../api/client";
import { GlobalContext } from "../../../context";
import MainLayouts from "../../../layout/MainLayouts";
import ErrorPage from "../../../page/ErrorPage";
import LoadingSpinner from "../../LoadingSpinner";
import VonCourseLiveCard from "../VonCourse/VonCourseLiveCard";
import VonCourseOnlineCard from "../VonCourse/VonCourseOnlineCard";

export default function VonAllCourses() {
  const [courses, setCourses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const { uuid } = useParams();
  const loadData = async () => {
    setIsLoading(true);
    try {
      const res = (await client.get(`v2/courses?category=${uuid}`)).data;
      setCourses(res.data);
    } catch (error) {
      setError(error);
    }
    setIsLoading(false);
  };

  // Filter courses based on course_id_type
  const sectionOneCourses = courses?.filter((course) => {
    return course?.course_type === "online";
  });

  const sectionTwoCourses = courses?.filter((course) => {
    return course?.course_type !== "online";
  });

  //   My code
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const [userLocation, setUserLocation] = useState(null); // State to hold user's location

  const {
    categoryState: { data },
  } = useContext(GlobalContext);
  useEffect(() => {
    loadData();
  }, [uuid]);
  return (
    <MainLayouts>
      <div className=" bg-[#f7f7f7]  min-[320px]:mt-[17%] min-[420px]:mt-[17%] min-[580px]:mt-[17%] lg:mt-[5%] py-[5%] ">
        {/* <div>
        <div></div>
        <div></div>
      </div> */}
        <div
          className="innersection"
          // style={{
          //   display: "flex",
          //   flexDirection: "row",
          //   alignItems: "center",
          //   justifyContent: "center",
          // }}
        >
          <div>
            <h1>{data?.find((item) => item?.id === uuid)?.id}</h1>
            <div className="sm:w-[80%] md:w-[100%] lg:w-1/2 mb-[3.5%]">
              <span
                className={
                  toggleState === 1
                    ? "inline-block w-1/2 px-[10px] py-4 border-t-solid border-t-[3px] border-t-[#d15242] bg-white cursor-pointer text-[#273044] text-center font-medium delay-0 duration-100 ease-in transition-colors text-xs min-[320px]:text-base"
                    : "inline-block w-1/2 px-[10px] py-4 border-t-solid border-t-[3px] border-t-[#f1f1f1] bg-[#f1f1f1] cursor-pointer text-[#273044] text-center font-medium delay-0 duration-100 ease-in transition-colors text-xs min-[320px]:text-base"
                }
                onClick={() => toggleTab(1)}
              >
                Online Courses
              </span>
              <span
                className={
                  toggleState === 2
                    ? "inline-block w-1/2 px-[10px] py-4 border-t-solid border-t-[3px] border-t-[#d15242] bg-white cursor-pointer text-[#273044] text-center font-medium delay-0 duration-100 ease-in transition-colors text-xs min-[320px]:text-base"
                    : "inline-block w-1/2 px-[10px] py-4 border-t-solid border-t-[3px] border-t-[#f1f1f1] bg-[#f1f1f1] cursor-pointer text-[#273044] text-center font-medium delay-0 duration-100 ease-in transition-colors text-xs min-[320px]:text-base"
                }
                onClick={() => toggleTab(2)}
              >
                Live Sessions
              </span>
            </div>
          </div>

          <div>
            {isLoading ? (
              <LoadingSpinner />
            ) : error ? (
              <ErrorPage errors={error} />
            ) : (
              <div>
                {/* Render section one courses */}

                <div className={toggleState === 1 ? "mb-4" : "hidden"}>
                  <div className="sm:w-[80%] lg:w-[55%] mb-[3.5%]">
                    <p>
                      Online Courses comprises of pre-recorded videos that can
                      be viewed at your convenience. Additionally, these courses
                      include clarity sessions and a master class.
                      {/* Clarity
                    sessions are live sessions designed to offer clarification
                    on course sections that you may have watched but require
                    assistance in understanding. The Master Class is aimed at
                    equipping you with the necessary tools and soft skills for
                    success in the corporate world. */}
                    </p>
                  </div>
                  <div className="flex flex-wrap gap-[1%]">
                    {sectionOneCourses
                      ?.sort((a, b) => b.is_free - a.is_free)
                      ?.map((course, i) => (
                        <VonCourseOnlineCard
                          key={i}
                          {...course}
                          currency={course.currency}
                          title={course.title}
                          duration={course.duration}
                          onViewCourse={() => {}}
                          loading="lazy"
                          price={
                            userLocation === "NG"
                              ? course.price_ngn
                              : course.price_usd
                          }
                        />
                      ))}
                  </div>
                </div>
                {/* Render section two courses */}
                <div className={toggleState === 2 ? "mb-4" : "hidden"}>
                  <div className="sm:w-[80%] lg:w-[55%] mb-[3.5%]">
                    <p>
                      Live Sessions are conducted in real-time. You will receive
                      class links to join the specific live course for which you
                      have registered.
                    </p>
                  </div>
                  <div className="flex flex-wrap gap-[1%]">
                    {sectionTwoCourses
                      ?.sort((a, b) => b.is_free - a.is_free)
                      ?.map((course, i) => (
                        <VonCourseLiveCard key={i} {...course} loading="lazy" />
                      ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </MainLayouts>
  );
}
