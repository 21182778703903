import React from "react";
import BecomeASponsorHeader from "../components/Sponsor/BecomeASponsor/BecomeASponsorHeader";
import BecomeASponsorSection from "../components/Sponsor/BecomeASponsor/BecomeASponsorSection";
import SponsorForm from "../components/Sponsor/BecomeASponsor/SponsorForm";
import SonsorFaq from "../components/Sponsor/SonsorFaq";
import MainLayouts from "../layout/MainLayouts";
export default function BecomeASposor() {

  return (
    <div>
      <MainLayouts>
      <BecomeASponsorHeader />
      <BecomeASponsorSection />
      <SponsorForm />
      <SonsorFaq />
      </MainLayouts>
    </div>
  );
}
