import { TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import client from "../api/client";
// import background from "../assets/images/sponsorbg1.png";
import background2 from "../assets/images/augustbg1.jpeg";
import background1 from "../assets/images/augustbg2.jpeg";
import Learn from "../components/August/Learn";
import CircularProgress from "../components/Generic/CircularProgress";
import { useIsMobile } from "../components/hooks/useIsMobile";
import MainLayouts from "../layout/MainLayouts";
function AugustCohort() {
  const defaultFormData = {
    email: "",
    first_name: "",
    last_name: "",
    phone: "",
    location: "",
    course: "",
    type: "summer",
  };
  const mobile = useIsMobile();
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState(null);
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      borderBottom: "1px solid #000",

      padding: 10,
    }),
    singleValue: (provided, state) => ({
      ...provided,
      marginLeft: 40, // Adjust this value according to your image size
    }),
    control: (provided, state) => ({
      ...provided,
      width: "100%",
    }),
  };
  const [formData, setFormData] = useState(defaultFormData);
  const [courses, setCourses] = useState();
  const [agreed, setAgreed] = useState(false);
  const [errors, setErrors] = useState({});
  const [courseLoading, setCourseLoading] = useState(false);

  const loadData = async () => {
    setCourseLoading(true);
    try {
      const res = (await client.get("/waitlist/august/courses")).data;

      setCourses(res);
    } catch (error) {
      console.log(error?.response);
    }
    setCourseLoading(false);
  };
  useEffect(() => {
    loadData();
  }, []);

  const [loading, setLoading] = useState(false);
  const handleSubmit = async (event) => {
    setLoading(true);
    event.preventDefault();
    try {
      // Check if formData.courses is empty or not provided
      if (formData?.course === "") {
        throw new Error("Please select a course to continue...");
      }

      const res = await client.post("/waitlist/august/apply", {
        ...formData,

        course: courses?.find((item) => item?.uuid === formData?.course)?.title,
      });
      setFormData(defaultFormData);

      toast.success("Your request was successful");
      setTimeout(() => {
        navigate("/");
      }, 4000);
    } catch (error) {
      console.log(error);
      toast.error(
        error.message || error?.response?.data?.message || "An error occurred"
      );
    }
    setLoading(false);
  };

  return (
    <MainLayouts>
      <div
        className="flex pt-[27%] md:pt-[9.1%] pb-[27%] md:pb-[9.1%]"
        style={{
          background: `url(${background1})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          minHeight: "90vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
          color: "#fff",
          position: "relative",
        }}
      >
        {/* Overlay */}
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the color and opacity as needed
            zIndex: 1,
          }}
        ></div>

        {/* Content */}
        <div
          className="innersection"
          style={{
            justifyContent: "center",
            position: "relative",
            display: "flex",
            width: "100%",
            zIndex: 2, // Ensure content appears above the overlay
          }}
        >
          <div
            style={{
              width: mobile ? "100%" : "40%",
            }}
          >
            <h1
              style={{
                fontSize: 40,
                fontWeight: "600",
              }}
            >
              Become A Certified Data Analyst In
            </h1>
            <h1
              style={{
                fontSize: 40,
                fontWeight: "600",
                color: "#d15242",
              }}
            >
              3 Months
            </h1>
          </div>
        </div>
      </div>
      <div>
        <Learn />
      </div>
      <div
        className="innersection"
        style={{
          padding: mobile ? "50px 15px" : "100px 0px",
        }}
      >
        <div
          style={{
            background: `url(${background2})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            // minHeight: "90vh",
            borderRadius: 30,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            color: "#fff",
            position: "relative",
            padding: mobile ? "40px 15px" : 100,
          }}
        >
          {/* Overlay */}
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              borderRadius: 30,
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust the color and opacity as needed
              zIndex: 1,
            }}
          ></div>

          {/* Content */}
          <div
            style={{
              width: "100%",
              zIndex: 2, // Ensure content appears above the overlay
            }}
          >
            <form onSubmit={handleSubmit}>
              <div className="flex items-center gap-3 mb-8">
                <TextField
                  required
                  value={formData.first_name}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      first_name: e.target.value,
                    });
                  }}
                  placeholder="First Name"
                  fullWidth
                  size="small"
                  InputProps={{
                    style: {
                      borderRadius: 20,
                      backgroundColor: "#fff", // Set the background color here
                    },
                  }}
                  sx={{
                    backgroundColor: "#fff", // Ensures the background color is consistent
                    borderRadius: 20,
                    "& .MuiOutlinedInput-root": {
                      // Apply border radius to the root of the input field
                      borderRadius: 20,
                    },
                  }}
                />
                <TextField
                  required
                  value={formData.last_name}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      last_name: e.target.value,
                    });
                  }}
                  placeholder="Last Name"
                  fullWidth
                  size="small"
                  InputProps={{
                    style: {
                      borderRadius: 20,
                      backgroundColor: "#fff", // Set the background color here
                    },
                  }}
                  sx={{
                    backgroundColor: "#fff", // Ensures the background color is consistent
                    borderRadius: 20,
                    "& .MuiOutlinedInput-root": {
                      // Apply border radius to the root of the input field
                      borderRadius: 20,
                    },
                  }}
                />
              </div>
              <div className=" mb-8">
                <TextField
                  required
                  value={formData.phone}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      phone: e.target.value,
                    });
                  }}
                  placeholder="Phone"
                  fullWidth
                  size="small"
                  InputProps={{
                    style: {
                      borderRadius: 20,
                      backgroundColor: "#fff", // Set the background color here
                    },
                  }}
                  sx={{
                    backgroundColor: "#fff", // Ensures the background color is consistent
                    borderRadius: 20,
                    "& .MuiOutlinedInput-root": {
                      // Apply border radius to the root of the input field
                      borderRadius: 20,
                    },
                  }}
                />
              </div>
              <div className=" mb-8">
                <TextField
                  required
                  value={formData.location}
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      location: e.target.value,
                    });
                  }}
                  placeholder="Location"
                  fullWidth
                  size="small"
                  InputProps={{
                    style: {
                      borderRadius: 20,
                      backgroundColor: "#fff", // Set the background color here
                    },
                  }}
                  sx={{
                    backgroundColor: "#fff", // Ensures the background color is consistent
                    borderRadius: 20,
                    "& .MuiOutlinedInput-root": {
                      // Apply border radius to the root of the input field
                      borderRadius: 20,
                    },
                  }}
                />
              </div>
              <div className=" mb-8">
                <TextField
                  placeholder="Email"
                  value={formData.email}
                  required
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      email: e.target.value,
                    });
                  }}
                  fullWidth
                  size="small"
                  InputProps={{
                    style: {
                      borderRadius: 20,
                      backgroundColor: "#fff", // Set the background color here
                    },
                  }}
                  sx={{
                    backgroundColor: "#fff", // Ensures the background color is consistent
                    borderRadius: 20,
                    "& .MuiOutlinedInput-root": {
                      // Apply border radius to the root of the input field
                      borderRadius: 20,
                    },
                  }}
                />
              </div>
              <div className=" mb-8">
                {courseLoading ? (
                  <p>Loading Course contents........</p>
                ) : (
                  <Select
                    options={courses?.map((course) => ({
                      label: (
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            borderRadius: 20,
                            color: "#000",
                          }}
                        >
                          <img
                            src={course?.images[0]}
                            alt={course?.title}
                            style={{ height: 30, width: 30, marginRight: 30 }}
                          />
                          {course?.title}
                        </div>
                      ),
                      value: course?.uuid, // Ensure you provide a unique value here
                    }))}
                    // Match the selected course
                    onChange={(selectedOption) => {
                      setFormData({
                        ...formData,
                        course: selectedOption?.value, // Use the value from the selected option
                      });
                    }}
                    styles={customStyles}
                    isClearable
                    placeholder="Select course"
                    required
                  />
                )}
              </div>
              <button
                style={{
                  borderRadius: 20,
                  width: "100%",
                }}
                className="h-5 bg-[#d15242] text-white py-5 px-12   text-xs flex items-center justify-center  hover:bg-white hover:text-black"
              >
                {loading ? <CircularProgress /> : "Apply"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </MainLayouts>
  );
}

export default AugustCohort;
