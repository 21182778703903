import React from "react";
import AboutSpecial from "../components/About/AboutSpecial";
import AboutTop from "../components/About/AboutTop";
import MainLayouts from "../layout/MainLayouts";
function About() {
  return (
    <MainLayouts>
      <div className="innersection">
        <AboutTop />
      </div>

      <AboutSpecial />

      {/* <Ambition /> */}
      {/* <Choose /> */}
      {/* <Advisor /> */}
      {/* <Gallery /> */}
      {/* <More /> */}
    </MainLayouts>
  );
}

export default About;
