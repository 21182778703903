import React from "react";

export default function TextField({
  label,
  onChange = (e) => {},
  value,
  type = "text",
  width = "100%",
  placeholder,
  height = 50,
  required = false,
  containerStyle,
  inputStyle,
  multiple,
}) {
  return (
    <div
      style={{
        ...containerStyle,
        width: width,
      }}
    >
      <h1
        style={{
          marginBottom: 9,
        }}
      >
        {label}
      </h1>
      <input
        placeholder={placeholder}
        value={value}
        required={required}
        onChange={onChange}
        multiple={multiple}
        style={{
          ...inputStyle,
          height: height,
          width: "100%",
          background: "transparent",
          border: "1px solid #CCCCCC",
          paddingLeft: 15,
          borderRadius: 10,
        }}
      />
    </div>
  );
}
