import axios from "axios";
import React, { useState } from "react";

import { toast } from "react-toastify";
const Contact = () => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSendMessage = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.set("name", name);
    formData.set("phone", phone);
    formData.set("email", email);
    formData.set("details", message); // Assuming "details" is the correct key

    try {
      const response = await axios.post(
        "https://e1xstaging.edoubleone.com/api/get_in_touch",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      setName("");
      setPhone("");
      setEmail("");
      setMessage("");
      toast("Thank You For Contacting Ex1Academy", response.data);

      // Handle response data if needed
      // console.log("Response:", response.data);
    } catch (error) {
      // Handle error
      toast("Error sending message:", error);
    }
  };

  return (
    <div className="w-[80%] sm:w-full lg:w-1/2 mx-auto mb-[5%]">
      <div className="innersection">
        <form onSubmit={handleSendMessage}>
          <h1 className="font-bold text-3xl text-center mb-[5%]">
            Keep in Touch
          </h1>
          <input
            type="text"
            id="name"
            name="name"
            placeholder="Full Name"
            className="inline-block w-full mb-[2.5%] outline-transparent border-b-gray-200 focus:border-b-[#0352E6] transition-colors duration-100 delay-0 ease-out border-b-[1px] pb-2 placeholder:text-sm placeholder:text-center text-center"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="text"
            id="phone"
            name="phone"
            placeholder="Phone Number"
            className="inline-block w-full mb-[2.5%] outline-transparent border-b-gray-200 focus:border-b-[#0352E6] transition-colors duration-100 delay-0 ease-out border-b-[1px] pb-2 placeholder:text-sm placeholder:text-center text-center"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
          <input
            type="text"
            id="email"
            name="email"
            placeholder="Email Address"
            className="inline-block w-full mb-[2.5%] outline-transparent border-b-gray-200 focus:border-b-[#0352E6] transition-colors duration-100 delay-0 ease-out border-b-[1px] pb-2 placeholder:text-sm placeholder:text-center text-center"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <textarea
            type="text"
            id="message"
            name="message"
            placeholder="Type Your Message Here"
            className="inline-block w-full mb-[2.5%] outline-transparent border-b-gray-200 focus:border-b-[#0352E6] transition-colors duration-100 delay-0 ease-out border-b-[1px] pb-2 placeholder:text-sm placeholder:text-center h-24"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <button
            type="submit" // Specify button type as "submit" to trigger form submission
            className="text-lg font-bold rounded text-white px-4 py-5 bg-[length:200%_100%] bg-right-bottom hover:bg-left-bottom bg-gradient-to-r from-[#D15424_50%] to-[#273044_50%] transition-all delay-0 duration-300 ease-out cursor-pointer mx-auto block"
          >
            SEND MESSAGE
          </button>
        </form>
      </div>
    </div>
  );
};

export default Contact;
