import { Grid } from "@mui/material";
import Image from "../../../assets/images/achievement.png";
import Animator from "../../../components/Aminator/Animator";
import { useIsMobile } from "../../../components/hooks/useIsMobile";
function Acheivement({ navigate }) {
  const mobile = useIsMobile();
  const items = [
    { name: "Online Classes", value: "30+" },
    { name: "Happy Students", value: "1500+" },
    { name: "Certified Instructors", value: "20+" },
    { name: "Countries", value: "50+" },
  ];
  return (
    <div
      className=" flex  pt-[27%] md:pt-[9.1%]  bg-[#f1f1f1] px-[0%] md:px-[7%]   pb-[27%]  md:pb-[9.1%]   "
      style={{
        // minHeight: "90vh",
        overflowX: "hidden",
        flexDirection: "column",
        justifyContent: "center",
        gap: 30,
      }}
    >
      <div className="innersection">
        <Grid container spacing={mobile ? 2 : 12} alignItems={"center"}>
          <Grid item xs={12} md={6}>
            <Animator
              direction="right"
              delay={500}
              timeout={500}
              variant="slide"
            >
              <img src={Image} style={{ width: "100%" }} />
            </Animator>
          </Grid>
          <Grid item xs={12} md={6}>
            <Animator
              style={{}}
              // direction="x"
              delay={300}
              timeout={300}
              variant="zoom"
            >
              <div>
                <div className="flex items-center gap-2">
                  <div
                    style={{
                      background: "#d15424",
                      height: 2,
                      width: 20,
                    }}
                  />
                  <p
                    style={{
                      color: "#aaa",
                      fontWeight: 300,
                    }}
                  >
                    Achievements
                  </p>
                  <div
                    style={{
                      background: "#d15424",
                      height: 2,
                      width: 20,
                    }}
                  />
                </div>
                <h1
                  style={{
                    fontSize: mobile ? 35 : 42,
                    lineHeight: 1.3,
                    fontWeight: 600,
                  }}
                >
                  Our Academic Achievements over the years
                </h1>

                <div className="mt-5">
                  <p
                    style={{
                      fontSize: mobile ? 14 : "",
                    }}
                  >
                    Empower yourself with the knowledge and skills gained
                    through online education! The key to your future! Empower
                    yourself with the knowledge and skills gained through online
                    education! The key to your future!
                  </p>
                  <div className="mt-5">
                    <button
                      onClick={() => {
                        navigate("/courses/categories");
                      }}
                      className="h-5 bg-[#d15242] text-white py-6 px-12 rounded-md  text-xs flex items-center justify-center  hover:bg-white hover:text-black"
                    >
                      View All Courses
                    </button>
                  </div>
                </div>
              </div>
            </Animator>
          </Grid>
        </Grid>

        <div>
          <Grid container spacing={6} mt={3}>
            {items?.map((cur, i) => (
              <Grid item xs={6} md={3} key={i}>
                <div
                  className="flex items-center text-center w-[100%] sinusoidal-wave-animation"
                  style={{
                    justifyContent: "center",
                    flexDirection: "column",
                    animationDelay: `${i * 0.2}s`, // stagger the animation
                  }}
                >
                  <h1
                    style={{
                      fontSize: mobile ? 20 : 28,
                      fontWeight: 600,
                      textAlign: "center",
                    }}
                  >
                    {cur.value}
                  </h1>
                  <p
                    style={{
                      fontSize: mobile ? 14 : "",
                    }}
                  >
                    {cur.name}
                  </p>
                </div>
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </div>
  );
}
export default Acheivement;
