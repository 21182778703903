import React from "react";
import Course from "../components/CourseDetails/Course";

import Reviews from "../components/CourseDetails/Reviews";
import StudentReviews from "../components/CourseDetails/StudentReviews";
import MainLayouts from "../layout/MainLayouts";

const OnlineCourseDetail = () => {
  return (
    <MainLayouts>
    <div className="md:mt-[15%] lg:mt-[10%]">
      <Course />

      {/* <Reviews />
      <StudentReviews /> */}
    </div>
    </MainLayouts>
  );
};

export default OnlineCourseDetail;
