import { Link } from "react-router-dom";
import marque1 from "./assets/marque1.webp";
import marque2 from "./assets/marque2.webp";
import marque3 from "./assets/marque3.webp";

export default function AboutSpecial() {
  return (
    <>
      <div className="bg-no-repeat bg-cover bg-center bg-[#f7f7f7] pb-20">
        <div className="innersection">
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            <div className="sm:w-[65%] lg:w-[55%] py-[10%] ">
              <h4 className="font-bold text-[28px] mb-[1.5%]">Why Choose Us</h4>
              <p className="mb-[1%]">
                At E1X Academy, we pride ourselves on offering exceptional and
                stimulating content that leaves a lasting impression on our
                students. Our team of expert instructors brings years of
                industry experience and academic expertise to the table,
                ensuring that you receive top-quality instruction every step of
                the way. From technical skills like coding and data analysis to
                soft skills like communication and leadership, we offer courses
                across a diverse array of subjects to meet your unique learning
                needs.
              </p>
              <p className="mb-[1%]">
                With our flexible online platform, you can learn at your own
                pace, from anywhere in the world, and on any device. Whether you
                prefer to dive deep into a single subject or sample a variety of
                topics, our intuitive interface makes it easy to navigate
                through our extensive course catalog and track your progress
                along the way.
              </p>
              <p className="mb-[1%]">
                Join the thousands of learners who have already discovered the
                transformative power of education with E1X Academy. Start your
                learning journey today and unlock your full potential!
              </p>
              <Link
                to="/sign-up" // Specify button type as "submit" to trigger form submission
                className="text-lg font-bold rounded text-white px-4 py-5 bg-[length:200%_100%] bg-right-bottom hover:bg-left-bottom bg-gradient-to-r from-[#D15424_50%] to-[#273044_50%] transition-all delay-0 duration-300 ease-out cursor-pointer inline-block mt-[2.5%]"
              >
                REGISTER NOW
              </Link>
            </div>
          </div>
          <div className="overflow-hidden">
            <div className="marquee-wrapper">
              <div className="marquee flex">
                <div className="flex marquee-group">
                  <div className="overflow-hidden relative before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-[#00000060]">
                    <img
                      src={marque1}
                      width={720}
                      height={798}
                      alt="marquee-image"
                      className="w-full"
                    />
                  </div>
                  <div className="overflow-hidden relative before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-[#00000060]">
                    <img
                      src={marque2}
                      width={720}
                      height={798}
                      alt="marquee-image"
                      className="w-full"
                    />
                  </div>
                  <div className="overflow-hidden relative before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-[#00000060]">
                    <img
                      src={marque3}
                      width={720}
                      height={798}
                      alt="marquee-image"
                      className="w-full"
                    />
                  </div>
                </div>
                <div className="aria-hidden flex marquee-group">
                  <div className="overflow-hidden relative before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-[#00000060]">
                    <img
                      src={marque1}
                      width={720}
                      height={798}
                      alt="marquee-image"
                      className="w-full"
                    />
                  </div>
                  <div className="overflow-hidden relative before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-[#00000060]">
                    <img
                      src={marque2}
                      width={720}
                      height={798}
                      alt="marquee-image"
                      className="w-full"
                    />
                  </div>
                  <div className="overflow-hidden relative before:absolute before:top-0 before:left-0 before:w-full before:h-full before:bg-[#00000060]">
                    <img
                      src={marque3}
                      width={720}
                      height={798}
                      alt="marquee-image"
                      className="w-full"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
        </div>
      </div>
    </>
  );
}
