import React, { useContext, useEffect, useState } from "react";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { GlobalContext } from "../../context";
import ErrorPage from "../../page/ErrorPage";
import { FetchCurrentWatchingCourse } from "../../utility";
import VonCourseLiveCard from "../AllVon/VonCourse/VonCourseLiveCard";
import VonCourseOnlineCard from "../AllVon/VonCourse/VonCourseOnlineCard";
import LoadingSpinner from "../LoadingSpinner";

export default function MyCourse({ targetRef, type }) {
  const [error, setError] = useState(null);
  const { authState, userCoursesState, onlineCoursesState } =
    useContext(GlobalContext);
  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const courses = userCoursesState?.data;

  // Filter courses based on course_id_type
  const sectionMyOneCourses =
    courses?.length > 0
      ? courses?.filter((course) => {
          return course?.course_type === "online";
        })
      : [];

  const sectionMyTwoCourses =
    courses?.length > 0
      ? courses?.filter((course) => {
          return course?.course_type === "live";
        })
      : [];
  const [data, setData] = useState({});

  const currentWatchingStatus = async () => {
    const res = await FetchCurrentWatchingCourse(
      authState?.data?.id?.toString()
    );
    // console.log("prbjks", res.status);
    if (res.status) {
      setData(res.data);
    }
  };
  useEffect(() => {
    currentWatchingStatus();
  }, []);
  useEffect(() => {
    if (type === "live") {
      setToggleState(2);
    } else {
      setToggleState(1);
    }
  }, [type]);

  return (
    <div
      className=""
      style={{
        minHeight: "20vh",
      }}
    >
      <div className="">
        {userCoursesState?.loading && userCoursesState?.data?.length === 0 ? (
          <LoadingSpinner />
        ) : error ? (
          <ErrorPage errors={error} />
        ) : (
          <>
            <div
              ref={targetRef}
              className="sm:w-[80%] md:w-[100%] lg:w-1/2 mb-[3.5%]"
            >
              <span
                className={
                  toggleState === 1
                    ? "inline-block w-1/2 px-[10px] py-4 border-t-solid border-t-[3px] border-t-[#d15242] bg-white cursor-pointer text-[#273044] text-center font-medium delay-0 duration-100 ease-in transition-colors text-xs min-[320px]:text-base"
                    : "inline-block w-1/2 px-[10px] py-4 border-t-solid border-t-[3px] border-t-[#f1f1f1] bg-[#f1f1f1] cursor-pointer text-[#273044] text-center font-medium delay-0 duration-100 ease-in transition-colors text-xs min-[320px]:text-base"
                }
                onClick={() => toggleTab(1)}
              >
                Online Courses
              </span>
              <span
                className={
                  toggleState === 2
                    ? "inline-block w-1/2 px-[10px] py-4 border-t-solid border-t-[3px] border-t-[#d15242] bg-white cursor-pointer text-[#273044] text-center font-medium delay-0 duration-100 ease-in transition-colors text-xs min-[320px]:text-base"
                    : "inline-block w-1/2 px-[10px] py-4 border-t-solid border-t-[3px] border-t-[#f1f1f1] bg-[#f1f1f1] cursor-pointer text-[#273044] text-center font-medium delay-0 duration-100 ease-in transition-colors text-xs min-[320px]:text-base"
                }
                onClick={() => toggleTab(2)}
              >
                Live Sessions
              </span>
            </div>
            {data?.title && (
              <>
                <h4 className="text-xl font-bold mb-[2%]">
                  Continue Online Course
                </h4>
                <div className="flex  flex-wrap mb-[5%] gap-[5%] md:gap-[1%] ">
                  {sectionMyOneCourses.length === 0 ? (
                    <p>You have not purchased any online courses.</p>
                  ) : (
                    data && (
                      <VonCourseOnlineCard
                        view={true}
                        // key={data.id}
                        images={data.images}
                        currency={data.currency}
                        title={data.title}
                        duration={data.duration}
                        onViewdata={() => {}}
                        loading="lazy"
                        uuid={data.uuid}
                        price={data.price}
                        course_level_id={data.course_level_id}
                        what_you_will_learn={data.what_you_will_learn}
                      />
                    )
                  )}
                </div>
              </>
            )}

            <div className={toggleState === 1 ? "block" : "hidden"}>
              <div className="flex flex-wrap mb-[5%] gap-[5%] md:gap-[1%]">
                {sectionMyOneCourses?.length === 0 ? (
                  <p>You have not purchased any online courses.</p>
                ) : (
                  sectionMyOneCourses?.map((course, i) => (
                    <VonCourseOnlineCard key={i} {...course} loading="lazy" />
                  ))
                )}
              </div>
            </div>

            {/* Render section two courses */}

            <div className={toggleState === 2 ? "block" : "hidden"}>
              <div className="flex flex-wrap gap-[5%] md:gap-[1%] mb-4">
                {sectionMyTwoCourses?.length === 0 ? (
                  <p>You have not purchased any Live Sessions.</p>
                ) : (
                  sectionMyTwoCourses?.map((course, i) => (
                    <VonCourseLiveCard {...course} loading="lazy" key={i} />
                  ))
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
