import DOMPurify from "dompurify";

const VonDisplayText = ({ htmlContent, maxLength }) => {
  const sanitizedHtml = DOMPurify.sanitize(htmlContent);

  const shortenedText =
    maxLength && sanitizedHtml.length > maxLength
      ? `${sanitizedHtml.substring(0, maxLength)}...`
      : sanitizedHtml;

  return (
    <div>
      <p
        style={{
          fontSize: 12,
        }}
        dangerouslySetInnerHTML={{ __html: shortenedText }}
      />
    </div>
  );
};

export default VonDisplayText;
