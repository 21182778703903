import React, { useState } from "react";
import validator from "validator";

import { NavLink, useNavigate } from "react-router-dom";
// import { AuthContext } from "../../../services/LoginAuthContext";
import { useContext } from "react";
import { toast } from "react-toastify";
import { PiEyeLight, PiEyeSlashLight } from "react-icons/pi";
// import client from "../../../api/client";
import CircularProgress from "../../Generic/CircularProgress";
import { GlobalContext } from "../../../context";
import { signInHandler } from "../../../context/actions/auth";

export default function VonLoginModal({
  switchToRegister,
  onClose = () => {},
}) {
  const {
    authDispatch,
    authState: { data },
  } = useContext(GlobalContext);
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const togglePasswordVisibility = (e) => {
    e.stopPropagation(); // Prevent the event from propagating to parent elements
    setShowPassword((prevState) => !prevState);
  };

  const validateForm = () => {
    let newErrors = {};
    let isValid = true;

    if (!validator.isEmail(email)) {
      newErrors.email = "Invalid email address";
      isValid = false;
    }

    if (validator.isEmpty(password)) {
      newErrors.password = "Password is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    const isValid = validateForm();
    if (isValid) {
      const res = await signInHandler(
        { email: email, password: password },
        authDispatch
      );
      if (res) {
        onClose();
        setEmail("");
        setPassword("");
        navigate("/");
      }
    } else {
      toast.error("Form validation failed. Please check the inputs.");
    }
    setLoading(false);
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className="px-[10%] py-[6%] bg-white mx-auto rounded h-[400px]"
      >
        <h2 className="text-2xl font-bold text-center mb-[5%]">
          Login with your account
        </h2>
        <div>
          <input
            type="email"
            name="email"
            value={email}
            placeholder="Email"
            onChange={handleEmailChange}
            required
            className="w-full inline-block border-solid border-[1px] border-[#eee] py-2 px-5 rounded placeholder:text-xs text-sm mb-[5%]"
          />
        </div>
        {errors.email && <p className="text-red-400">{errors.email}</p>}

        <div className="">
          <div className="relative">
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder="Password"
              value={password}
              onChange={handlePasswordChange}
              required
              className={`w-full inline-block border-solid border-[1px] border-[#eee] py-2 px-5 rounded placeholder:text-xs text-sm"
							}`}
            />
            <span className="absolute top-1/2 right-4 transform -translate-y-1/2">
              {showPassword ? (
                <PiEyeSlashLight onClick={togglePasswordVisibility} />
              ) : (
                <PiEyeLight onClick={togglePasswordVisibility} />
              )}
            </span>
          </div>

          {errors.password && (
            <p className="text-sm text-red-400">{errors.password}</p>
          )}
        </div>

        <NavLink
          to="/forgot-password"
          className="text-sm mt-[1%] text-primary-color text-right block"
        >
          Forgot password
        </NavLink>

        <button
          type="submit"
          className="w-full py-2 px-5 rounded text-sm bg-primary-color hover:bg-[#884c34] delay-0 duration-150 transition-colors ease-in text-white font-medium mt-[5%]"
        >
          {loading ? <CircularProgress /> : "LOGIN"}
        </button>

        <p className="mt-[2%] text-sm text-center font-medium">
          Don't have an account?{" "}
          <a href="/sign-up">
            <span
              className="text-primary-color cursor-pointer"
              onClick={switchToRegister}
            >
              Register Now
            </span>
          </a>
          .
        </p>
      </form>
    </div>
  );
}
