import { Grid } from "@mui/material";
import React from "react";
import BackgroundSlider from "react-background-slider";
import { useNavigate } from "react-router-dom";
import { Typewriter } from "react-simple-typewriter";
import background2 from "../../../assets/images/sponsorbg1.png";
import background from "../../../assets/images/sponsorbg3.png";
import headerImage from "../../../assets/images/video.png";
import Animator from "../../Aminator/Animator";
import { useIsMobile } from "../../hooks/useIsMobile";
export default function BecomeASponsorHeader() {
  const mobile = useIsMobile();
  const navigate = useNavigate();
  return (
    <>
      <div
        className="mt-[30%] md:mt-[9.2%]"
        style={{
          position: "relative",
          minHeight: "100vh",
          // backgroundImage: `url(${background})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",

          zIndex: -1,
        }}
      >
        <BackgroundSlider
          images={[background, background2]}
          duration={10}
          transition={1}
        />
        <div
          style={{
            // background: `url(${background})`,
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
            minHeight: "90vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            color: "#fff",
          }}
        >
          <div
            className="innersection"
            style={{
              width: mobile ? "100%" : "40%",
            }}
          >
            <h1
              style={{
                fontWeight: 550,
                fontSize: 56,
                lineHeight: 1,
              }}
            >
              Become a sponsor
            </h1>
            <p
              style={{
                marginTop: 26,
                fontSize: 18,
              }}
            >
              Interested in the work we do? Join us in providing opportunities
              to deserving individuals. Invest in shaping a brighter future
              today.
            </p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <button
                onClick={() => {
                  navigate("/sponsor/participate");
                }}
                style={{
                  width: mobile ? "100%" : "40%",
                  height: 50,
                  background: "#d15242",
                  marginTop: 40,
                  borderRadius: 10,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#fff",
                }}
              >
                Join Us
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{
          background: "#d15424",
          // padding: "20px 0px",
        }}
      >
        <div className="innersection">
          <Grid container spacing={12}>
            <Grid item sx={12} md={6}>
              <div
                style={{
                  color: "#fff",
                }}
              >
                <h1
                  style={{
                    fontWeight: 550,
                    fontSize: 30,
                    textAlign: mobile ? "center" : "",
                  }}
                >
                  <Typewriter delaySpeed={100} words={["Why we need you!"]} />
                </h1>
                <Animator delay={400} timeout={400} variant="fade">
                  <p
                    style={{
                      marginTop: 30,
                    }}
                  >
                    The demand for skilled data analysts is rapidly increasing,
                    while a sizable young population seeks lucrative jobs and
                    careers. Our goal is to bridge this gap. However, the
                    limited purchasing power of those who need these courses
                    poses a significant challenge. In an effort to address this
                    issue, we have consistently tried to provide our courses for
                    free to these individuals whenever possible. With your
                    assistance, we can expand our reach and make a broader
                    impact.
                  </p>
                </Animator>
              </div>
            </Grid>
            <Grid item sx={12} md={6}>
              <div
                style={{
                  display: mobile ? "none" : "flex",
                  position: "relative",
                  width: "100%",
                }}
              >
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    // objectFit: "contain",

                    // bottom: "-70px",
                  }}
                  src={headerImage}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
}
