import { Grid } from "@mui/material";
import Image from "../../../assets/images/liveecourseimage.png";
import Animator from "../../../components/Aminator/Animator";
import { useIsMobile } from "../../../components/hooks/useIsMobile";
function LiveCoursesSection({ navigate }) {
  const mobile = useIsMobile();
  const data = [
    "Learn From The Comfort Of Your Living Room",
    "Be Matched With Other Learners And Coaches",
    "Work On Trade Cases And Capstone Projects",
    "Live Classes Means You Can Archive Your Entire Class",
    "Flexible Payment",
  ];
  return (
    <div
      className=" flex items-center pt-[27%] md:pt-[2.1%]  bg-[#f7f7f7] px-[0%] md:px-[7%]   pb-[27%]  md:pb-[1%]  "
      style={{
        minHeight: "90vh",
        overflowX: "hidden",
      }}
    >
      <div className="innersection">
        <Grid container spacing={mobile ? 2 : 12} alignItems={"center"}>
          <Grid item xs={12} md={6}>
            <img
              src={Image}
              style={{ width: "100%" }}
              className="bouncing-image"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <div>
              <h1
                style={{
                  fontSize: mobile ? 35 : 42,
                  lineHeight: 1.3,
                  fontWeight: 600,
                  marginBottom: 20,
                }}
              >
                Instructor-
                <span
                  style={{
                    color: "#d15424",
                  }}
                >
                  Led Live classes{" "}
                </span>
              </h1>

              <p
                style={{
                  fontSize: mobile ? 14 : "",
                }}
              >
                Elementa is an online learning platform specifically designed to
                help you learn chemistry easily, fun and effectively.
              </p>
              <div className="mt-10">
                {data?.map((cur, i) => (
                  <Animator
                    key={i}
                    delay={
                      i === 0
                        ? 200
                        : i === 1
                        ? 300
                        : i === 2
                        ? 400
                        : i === 3
                        ? 500
                        : 1 === 4
                        ? 600
                        : 700
                    }
                    variant="zoom"
                    timeout={500}
                  >
                    <div
                      className="flex items-center gap-5 rounded-lg mb-4 "
                      style={{
                        borderRadius: 30,
                        minHeight: 30,
                        width: "100%",
                        background: "#Ffff",
                      }}
                    >
                      <div
                        style={{
                          height: 28,
                          width: 28,
                          background: "#d15424",
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                          color: "#fff",
                          borderRadius: 30,
                        }}
                      >
                        {i + 1}
                      </div>
                      <p
                        style={{
                          fontSize: mobile ? 10 : 15,
                        }}
                      >
                        {cur}
                      </p>
                    </div>
                  </Animator>
                ))}
                <div className="mt-10">
                  <button
                    onClick={() => {
                      navigate("/courses/categories");
                    }}
                    className="h-5 bg-[#d15242] text-white py-6 px-12 rounded-md  text-xs flex items-center justify-center  hover:bg-white hover:text-black"
                  >
                    View All Courses
                  </button>
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
export default LiveCoursesSection;
