import { Home } from "@mui/icons-material";
import {
  ButtonBase,
  CircularProgress,
  Grid,
  Hidden,
  TextField,
} from "@mui/material";
import { getDatabase, onValue, ref } from "firebase/database";
import { useContext, useEffect, useState } from "react";
import { FaArrowLeft, FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useIsMobile } from "../../src/components/hooks/useIsMobile";
import logo from "../assets/images/logo.jpeg";
import Chat from "../components/Community/Chat";
import ChatLeft from "../components/Community/ChatLeft";
import { GlobalContext } from "../context";
import { FetchUserCommunityChatGroup } from "../context/actions/courses";
import { updateStarCount } from "../utility";
function Community() {
  const [selectedChat, setSelectedChat] = useState();
  const [chatData, setChatData] = useState({});
  const [chatId, setChatId] = useState("");
  const [loading, setLoading] = useState(false);
  const {
    communityDispatch,
    communityState,
    authState: { loggedIn },
  } = useContext(GlobalContext);
  const mobile = useIsMobile();
  const loadCommunityCohort = async () => {
    const res = FetchUserCommunityChatGroup(communityDispatch);
  };
  const [showLeft, setShowLeft] = useState(true);
  // console.log("data", communityState);
  const [chatName, setChatName] = useState("");
  const loadChat = async () => {
    setLoading(true);
    // Assuming postElement is a DOM element where you want to display the star count
    const postElement = document.getElementById(chatId);
    const db = getDatabase();

    const starCountRef = ref(db, "chats/" + chatId);
    onValue(starCountRef, (snapshot) => {
      const data = snapshot.val();
      updateStarCount(postElement, data);
      if (data) {
        const res = Object.keys(data)?.map((cur) => {
          // console.log("val", data[cur]);
          return { ...data[cur], chatId: cur };
        });
        setChatData(res);
      } else {
        setChatData([]);
      }
    });

    setTimeout(() => {
      setLoading(false);
    }, 500);
  };
  useEffect(() => {
    if (chatId != "") {
      loadChat();
    }
  }, [chatId]);
  const navigate = useNavigate();
  useEffect(() => {
    if (!loggedIn) {
      toast.error("you need to login to access this page");
      navigate("/");
    }
  }, [loggedIn]);
  useEffect(() => {
    loadCommunityCohort();
  }, []);
  return (
    // <MainLayouts showFooter={false}>
    <div
      // className=" mt-[27%] md:mt-[9.1%]"
      style={{
        background: mobile ? "#f7f7f7" : "#111",
        minHeight: "100vh",
      }}
    >
      <Grid container>
        <Hidden mdDown={showLeft}>
          <Grid item md={3} xs={12}>
            <div
              style={{
                height: "100vh",
                width: "100%",
                background: mobile ? "#111" : "",
                color: "#fff",
              }}
            >
              <div style={{ padding: "20px 25px" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <img
                    onClick={() => {
                      navigate("/");
                    }}
                    src={logo}
                    style={{
                      width: 100,
                      height: 50,
                      cursor: "pointer",
                      objectFit: "contain",
                    }}
                  />
                  {mobile && (
                    <ButtonBase
                      onClick={() => {
                        setShowLeft(!showLeft);
                        setChatName("");
                        setChatId("");
                        setChatData([]);
                      }}
                    >
                      <Home />
                    </ButtonBase>
                  )}
                </div>
                {!mobile && (
                  <header
                    style={{
                      marginTop: 27,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <ButtonBase
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      <FaArrowLeft
                        style={{
                          fontSize: 25,
                        }}
                      />
                    </ButtonBase>
                    <h1
                      style={{
                        fontSize: 20,
                      }}
                    >
                      Community
                    </h1>
                    <FaEdit
                      style={{
                        fontSize: 25,
                      }}
                    />
                  </header>
                )}

                <TextField
                  placeholder="Search cohorts"
                  style={{
                    marginTop: 25,
                  }}
                  size="small"
                  fullWidth
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "#fff",
                        borderWidth: 1,
                      },
                      "&:hover fieldset": {
                        borderColor: "#fff",
                        borderWidth: 1,
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "#fff",
                        borderWidth: 1,
                      },
                    },
                    "& .MuiInputBase-input": {
                      color: "#fff",
                    },
                  }}
                  InputProps={{
                    style: {
                      color: "#fff",
                    },
                  }}
                />
              </div>
              <div
                style={{
                  height: "70vh",
                  overflowY: "scroll",
                }}
              >
                <ChatLeft
                  value={chatId}
                  data={communityState?.data}
                  onClick={(e) => {
                    setChatName(e.name);
                    setChatId(e?.id);
                    setShowLeft(!showLeft);
                  }}
                />
              </div>
            </div>
          </Grid>
        </Hidden>
        {mobile ? (
          showLeft && (
            <Grid item md={9} xs={12}>
              <div
                style={{
                  position: "relative",
                  height: "100vh",
                  flex: 1,
                  background: "#f7f7f7",
                  borderTopLeftRadius: mobile ? 0 : 40,
                  borderBottomLeftRadius: mobile ? 0 : 40,
                }}
              >
                {loading && (
                  <div
                    style={{
                      display: "flex",
                      height: "100vh",
                      width: mobile ? "100%" : "75%",
                      // width: "100vw",
                      background: "#fffa",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "fixed",
                      top: 0,
                      zIndex: 2000,
                    }}
                  >
                    <CircularProgress
                      style={{
                        color: "#d15242",
                      }}
                    />
                  </div>
                )}
                <div
                  style={
                    {
                      // height: "100vh",
                    }
                  }
                >
                  <Chat
                    onClickPlusButton={() => {
                      setShowLeft(!showLeft);
                    }}
                    chat={chatData}
                    chatId={chatId}
                    chatName={chatName}
                    mobile={mobile}
                  />
                </div>
              </div>
            </Grid>
          )
        ) : (
          <Grid item md={9} xs={12}>
            <div
              style={{
                position: "relative",
                height: "100vh",
                flex: 1,
                background: "#f7f7f7",
                borderTopLeftRadius: mobile ? 0 : 40,
                borderBottomLeftRadius: mobile ? 0 : 40,
              }}
            >
              {loading && (
                <div
                  style={{
                    display: "flex",
                    height: "100vh",
                    width: mobile ? "100%" : "75%",
                    // width: "100vw",
                    background: "#fffa",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "fixed",
                    top: 0,
                    zIndex: 2000,
                  }}
                >
                  <CircularProgress
                    style={{
                      color: "#d15242",
                    }}
                  />
                </div>
              )}
              <div
                style={
                  {
                    // height: "100vh",
                  }
                }
              >
                <Chat
                  onClickPlusButton={() => {
                    setShowLeft(!showLeft);
                  }}
                  chat={chatData}
                  chatId={chatId}
                  chatName={chatName}
                  mobile={mobile}
                />
              </div>
            </div>
          </Grid>
        )}

        {/* <Grid item md={2.5} xs={0}></Grid> */}
      </Grid>
    </div>
    // </MainLayouts>
  );
}
export default Community;
