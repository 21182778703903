import Compressor from "compressorjs";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import { app } from "./firebase";
export const uploadHelmper = (f) => {
  const storage = getStorage(app);

  return new Promise((resolve, reject) => {
    try {
      new Compressor(f, {
        quality: 0.5,
        success: async (compressedResult) => {
          const storageRef = ref(
            storage,
            `images/${Date.now()}_${compressedResult.name}`
          );
          const uploadTask = await uploadBytes(storageRef, compressedResult);
          const url = await getDownloadURL(uploadTask.ref);

          resolve({
            loading: false,
            url,
            name: compressedResult.name,
          });
        },
      });
    } catch (error) {
      resolve({
        loading: false,
        url: "",
        name: "",
      });
    }
  });
};
export const uploadHelmper2 = async (f, onProgress) => {
  const storage = getStorage(app);

  return new Promise((resolve, reject) => {
    try {
      const storageRef = ref(storage, `files/${Date.now()}_${f.name}`);
      const uploadTask = uploadBytesResumable(storageRef, f);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

          onProgress(progress);
        },
        (error) => {
          // Handle unsuccessful uploads
          console.log(error);
          resolve({
            loading: false,
            url: "",
            name: "",
          });
        },
        async () => {
          // Handle successful uploads on complete
          const url = await getDownloadURL(uploadTask.snapshot.ref);
          resolve({
            loading: false,
            url,
            name: f.name,
          });
        }
      );
    } catch (error) {
      console.log(error);
      resolve({
        loading: false,
        url: "",
        name: "",
      });
    }
  });
};
