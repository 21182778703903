import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  ButtonBase,
  CircularProgress,
  Dialog,
  Menu,
  MenuItem,
} from "@mui/material";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import client from "../../api/client";
import { getMimeType } from "../../utility";
import DeleteDialog from "./DeleteDialog";
import ReplyComponent from "./ReplyComponent";

function ChatComponent({
  cur,
  id,
  onReplyClick = () => {},
  data = [],
  cohortId,
  mobile,
}) {
  // const {} = useContext();
  const [readMore, setReadMore] = useState(false);
  const [options, setOptions] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [popup, setPopup] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState("");
  const [links, setLinks] = useState([]);
  const [linkPreview, setLinkPreview] = useState(null);
  const [loading, setLoading] = useState(false);

  // const messageRefs = useRef({});

  useEffect(() => {
    const extractLinks = (text) => {
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      return text.match(urlRegex);
    };

    setLinks(extractLinks(cur.message) || []);
  }, [cur.message]);

  useEffect(() => {
    if (links.length > 0 && !cur.image) {
      const fetchLinkPreview = async (url) => {
        try {
          setLoading(true);
          const response = await client.get(`/link-preview?url=${url}`);

          setLinkPreview(response.data);
        } catch (error) {
          console.error("Error fetching link preview", error);
        } finally {
          setLoading(false);
        }
      };

      fetchLinkPreview(links[0]);
    }
  }, [links, cur.image]);

  const handleImageClick = (image) => {
    setSelectedImage(image);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedImage("");
  };

  // const scrollToMessage = (chatId) => {
  //   const messageElement = messageRefs.current[chatId];
  //   if (messageElement) {
  //     messageElement.scrollIntoView({ behavior: "smooth", block: "start" });
  //   }
  // };

  return (
    <div>
      <DeleteDialog
        id={cohortId}
        chatId={cur.chatId}
        open={popup}
        onClick={() => {}}
        onClose={() => {
          setPopup(!popup);
        }}
      />
      <div
        // ref={(el) => (messageRefs.current[cur.chatId] = el)}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: cur.id === id ? "flex-end" : "flex-start",
          marginBottom: 10,
        }}
      >
        <div
          style={{
            cursor: "pointer",
            maxWidth: mobile ? "85%" : "40%",
            minWidth: "7%",
            minHeight: 50,
            flexWrap: "wrap",
            borderRadius:
              cur.id !== id ? "14px 14px 14px 0px" : "14px 14px 0px 14px",
            padding: "3px 10px",
            display: "flex",
            position: "relative",
            justifyContent: "center",
            flexDirection: "column",
            background: cur.id === id ? "#d7d7d7" : "#f7f7f7",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
          }}
          onMouseEnter={() => {
            setOptions(true);
          }}
          onMouseLeave={() => {
            setOptions(false);
          }}
        >
          {cur?.replyId && (
            <div
              // onClick={() => scrollToMessage(cur.replyId)}
              style={{ cursor: "pointer" }}
            >
              <ReplyComponent
                mobile={mobile}
                data={data?.find((item) => item?.chatId === cur.replyId)}
              />
            </div>
          )}

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: 5,
              marginTop: 8,
            }}
          >
            {cur.name && cur.id !== id ? (
              <p
                style={{
                  color: "#d15424",
                  fontSize: 10,
                }}
              >
                {cur.name}
              </p>
            ) : (
              <p
                style={{
                  color: "#fff",
                  fontSize: 10,
                }}
              >
                You
              </p>
            )}
            {options && (
              <ButtonBase
                id="basic-button"
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                onClick={handleClick}
                style={{
                  height: 10,
                  width: 10,
                }}
              >
                <KeyboardArrowDownIcon
                  style={{
                    color: cur.id !== id ? "#d15242" : "#fff",
                  }}
                />
              </ButtonBase>
            )}
            <Menu
              sx={{
                width: 200,
              }}
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                sx={{
                  marginBottom: 1,
                }}
                onClick={() => {
                  onReplyClick(cur?.chatId);
                  handleClose();
                  // scrollToMessage(cur?.chatId);
                }}
              >
                <h1 style={{}}> Reply Chat</h1>
              </MenuItem>
              {cur.id === id && (
                <MenuItem
                  onClick={() => {
                    setPopup(true);
                    handleClose();
                  }}
                >
                  <h1
                    style={{
                      fontSize: 15,
                    }}
                  >
                    Delete Chat
                  </h1>
                </MenuItem>
              )}
            </Menu>
          </div>
          {cur.type === "image" && (
            <img
              src={cur.image}
              alt="chat-img"
              style={{
                height: 280,
                width: "100%",
                minWidth: mobile ? 200 : 300,
                borderRadius: 10,
                marginBottom: 5,
                marginTop: 5,
                objectFit: "cover",
              }}
              onClick={() => handleImageClick(cur.image)}
            />
          )}
          {cur.type === "video" && (
            <video
              controls
              src={cur.videoUrl}
              style={{
                height: 280,
                width: "100%",
                borderRadius: 10,
                marginBottom: 5,
              }}
            />
          )}
          {cur.type === "doc" && cur.docUrl && (
            <a href={cur.docUrl} target="_blank" rel="noopener noreferrer">
              <ButtonBase style={{ width: "100%" }}>
                <iframe
                  title="doc"
                  style={{
                    overflow: "hidden",
                    height: 150,
                    width: "100%",
                    marginBottom: 5,
                  }}
                  src={`https://docs.google.com/gview?url=${encodeURIComponent(
                    cur.docUrl
                  )}&embedded=true`}
                  type={getMimeType(cur.docUrl)}
                  sandbox="allow-same-origin allow-scripts"
                />
              </ButtonBase>
            </a>
          )}
          <p
            style={{
              color: cur.id === id ? "#fff" : "#000",
              marginBottom: cur.id !== id && 5,
            }}
          ></p>
          {links.length > 0 && !cur.image && (
            <div
              style={{
                width: "100%",
                marginBottom: 5,
                whiteSpace: "normal",
                wordBreak: "break-word",
              }}
            >
              {loading ? (
                <CircularProgress
                  size={20}
                  style={{
                    color: "#d15242",
                  }}
                />
              ) : linkPreview ? (
                <div
                  style={{
                    border: "1px solid #aaa",
                    borderRadius: 10,
                    padding: 10,
                  }}
                >
                  {linkPreview.image && (
                    <img
                      src={linkPreview.image}
                      alt="link-preview"
                      style={{ width: "100%", borderRadius: 10 }}
                    />
                  )}
                  <h3
                    style={{
                      marginTop: 10,
                      fontSize: mobile ? 14 : "",
                      fontWeight: 600,
                    }}
                  >
                    {linkPreview.title}
                  </h3>
                  <p
                    style={{
                      marginTop: 10,
                      fontSize: mobile ? 12 : "",
                    }}
                  >
                    {linkPreview.description}
                  </p>
                </div>
              ) : (
                <p>Failed to load link preview</p>
              )}
            </div>
          )}
          <p>
            {cur.message.split(" ").map((word, index) => {
              if (links.includes(word)) {
                return (
                  <a
                    key={index}
                    href={word}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      whiteSpace: "normal",
                      wordBreak: "break-word",
                      color: cur.id === id ? "green" : "#d15424",
                      fontSize: mobile ? 12 : 15,
                      marginLeft: 2,
                    }}
                  >
                    {word}{" "}
                  </a>
                );
              }
              return word + " ";
            })}
          </p>
          <p
            style={{
              color: cur.id === id ? "#000" : "#d15424",
              fontSize: 10,
              marginTop: 2,
              textAlign: "end",
            }}
          >
            {format(new Date(cur.time), "yyyy-MM-dd")}
          </p>
        </div>
      </div>

      <Dialog
        open={dialogOpen}
        onClose={handleDialogClose}
        maxWidth="md"
        fullWidth
      >
        <img
          src={selectedImage}
          alt="chat-img-large"
          style={{ width: "100%", height: "auto" }}
        />
      </Dialog>
    </div>
  );
}

export default ChatComponent;
