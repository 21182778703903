// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAjTqe556NVsmzX7BJHB5EQ1v3aiJFdyKI",
  authDomain: "e1xacademy.firebaseapp.com",
  projectId: "e1xacademy",
  storageBucket: "e1xacademy.appspot.com",
  messagingSenderId: "478886101803",
  appId: "1:478886101803:web:22d2e4e3321b1f903abb6e",
  measurementId: "G-5JN3KN90T5",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const database = getFirestore(app);
export const storage = getStorage(app);
export const analytics = getAnalytics(app);
