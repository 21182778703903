import React, { useEffect, useState } from "react";

import SponsorHeader from "../components/Sponsor/SponsorHeader";
import ActiveScholarship from "../components/Sponsor/ActiveScholarship";
import SponsorScreen from "../components/Sponsor/SponsorScreen";
import SonsorFaq from "../components/Sponsor/SonsorFaq";
import Slider from "react-slick"; // Importing react-slick for carousel functionality
import "slick-carousel/slick/slick.css"; // Importing slick-carousel styles
import "slick-carousel/slick/slick-theme.css";
import client from "../api/client";
import MainLayouts from "../layout/MainLayouts";
export default function Sponsor() {
  const [loading, setLoading] = useState(false);
  const [sponsorsData, setSponsorsData] = useState([]);
  const loadData = async () => {
    setLoading(true);
    try {
      const res = (await client.get("/sponsors")).data;

      setSponsorsData(res);
    } catch (error) {
      console.log("error loading sponsors", error);
    }
    setLoading(false);
  };
  useEffect(() => {
    loadData();
  }, []);

  const CustomPrevArrow = ({ onClick }) => (
    <button
      onClick={onClick}
      style={{
        display: "none",
      }}
    >
      ◀
    </button>
  );

  const CustomNextArrow = ({ onClick }) => (
    <button
      onClick={onClick}
      style={{
        display: "none",
      }}
    >
      ▶
    </button>
  );
  const settings = {
    dots: true, // Show navigation dots
    infinite: true, // Loop through slides
    speed: 500, // Transition speed
    slidesToShow: 1, // How many slides to show at once
    slidesToScroll: 1, // How many slides to scroll
    autoplay: true, // Automatically switch slides
    autoplaySpeed: 3000, // Interval for auto-switching in milliseconds
    arrows: true, // Show control arrows
    prevArrow: <CustomPrevArrow />, // Custom previous arrow
    nextArrow: <CustomNextArrow />, // Custom next arrow
  };

  return (
    <MainLayouts>
      <SponsorHeader />
      {/* <ActiveScholarship /> */}
      <div style={{ position: "relative", width: "100%", height: "100%" }}>
        <div className="innersection">
          <Slider {...settings}>
            {sponsorsData?.map((cur, i) => (
              <SponsorScreen data={cur} key={i} />
            ))}
          </Slider>
        </div>
      </div>
      <SonsorFaq />
    </MainLayouts>
  );
}
