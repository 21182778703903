import { Navigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "../services/LoginAuthContext";
import { GlobalContext } from "../context";

const PrivateRoute = ({ Element, ...rest }) => {
  const {
    authState: { data, loggedIn },
    authDispatch,
  } = useContext(GlobalContext);
  const user = data?.user;
  const isAuthenticated = loggedIn;
  const isEmailVerified = user && !!user?.email_verified_at;

  if (
    rest.path &&
    rest.path.startsWith("/dashboard") &&
    isAuthenticated &&
    !isEmailVerified
  ) {
    return <Navigate to="/verify-email" />;
  }

  return isAuthenticated && isEmailVerified ? (
    Element
  ) : (
    <Navigate to="/sign-in" />
  );
};

export default PrivateRoute;
