import React from "react";
import headerImage from "../../../assets/images/sponsor5.png";
import Image from "../../../assets/images/sponsor3.png";
import { useIsMobile } from "../../hooks/useIsMobile";
import Animator from "../../Aminator/Animator";
import { Typewriter } from "react-simple-typewriter";

export default function BecomeASponsorSection() {
  const mobile = useIsMobile();
  return (
    <div
      style={{
        background: "#f7f7f7",
      }}
    >
      <div
        className="innersection"
        style={{
          padding: mobile ? "30px 15px" : "100px 0px",
          // minHeight: "80vh",
        }}
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center">
          <div className="w-full h-auto ">
            <Animator delay={400} timeout={400} variant="slide">
              <img
                src={headerImage}
                alt="Placeholder"
                style={{
                  height: mobile ? 400 : 500,
                  borderTopLeftRadius: 100,
                  borderBottomRightRadius: 100,
                }}
                className="w-full  object-cover"
              />
            </Animator>
          </div>
          <div className="text-center md:text-left">
            <h1
              style={{
                fontSize: 30,
                fontWeight: 600,
              }}
            >
              Sponsorship is an act of kindness and anyone can do that.
            </h1>
            <Animator delay={400} timeout={400} variant="grow">
              <p
                className="mt-4 text-gray-600"
                style={{
                  marginTop: 30,
                }}
              >
                Sponsorship is an act of kindness and anyone can do that. Your
                sponsorship is not just a financial investment; it is an act of
                compassion, and an opportunity to directly the lives of
                deserving people. By joining hands with us, you become a beacon
                of hope, lighting the way for those seeking a brighter tomorrow.
                We firmly believe that together, we can create a world where
                opportunities are accessible to all, where talents are nurtured,
                and where dreams can be realized. Become a sponsor today and
                help us achieve these goals
              </p>
            </Animator>
          </div>
        </div>
        <div
          style={{
            marginTop: 100,
            height: "100%",
          }}
          className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center"
        >
          <div className="text-center md:text-left">
            <div
              style={{
                display: mobile && "flex",
                justifyContent: mobile && "center",
                alignItems: mobile && "center",
              }}
            >
              <Animator delay={400} timeout={400} variant="zoom">
                <img
                  src={Image}
                  style={{
                    height: 150,
                    width: 150,
                    objectFit: "contain",
                    marginTop: 60,
                  }}
                />
              </Animator>
            </div>
            <div
              style={{
                minHeight: 80,
              }}
            >
              <h1
                style={{
                  fontSize: 30,
                  fontWeight: 600,
                }}
              >
                <Typewriter words={["Who you are sponsoring"]} loop />
              </h1>
            </div>
            <p
              className="mt-4 text-gray-600"
              style={{
                marginTop: 30,
              }}
            >
              Our sponsorship program is designed to be inclusive and accessible
              to everyone, regardless of their background or financial
              situation. However, we prioritize individuals from
              underrepresented demographics and people from low-income
              households. By prioritizing these groups, we aim to create a more
              diverse and equitable learning environment and to ensure that
              everyone has the opportunity to benefit from our educational
              offerings.  In addition, as a donor, you can decide who gets to
              benefit from your scholarship, so you can narrow your efforts down
              to the people who matter the most to you. Your choice makes a
              direct impact, ensuring that your generosity reaches those who
              need it most.We believe that everyone deserves access to
              high-quality education, and with your help, we are committed to
              making our programs affordable and accessible to all.
            </p>
          </div>
          <div
            className="w-full h-auto "
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "space-between",

              height: "100%",
            }}
          >
            {[
              {
                name: "Oluwakemi Oyebanji",
                details:
                  "I  hope to gain the necessary skills and qualifications through this cohort needed for professional growth and advancement. I am extremely grateful to have been given this opportunity.",
              },
              {
                name: "Edwina Ilomechine",
                details:
                  "I have been trying to learn skills in data analytics especially the Power BI and SQL but due to the lack of funds I was not able to learn this skill. Personalized batch (Maximum 15 students) Verbally ask question Lifetime dedicated what’s app group Premium resources",
              },
              {
                name: "Ogechi Onovo",
                details:
                  " I am thrilled to be part of the recipients of this scholarship, as it represents an incredible opportunity for growth and learning in a field that is shaping the future",
              },
              {
                name: "Folowosele Oluwapelumi",
                details:
                  "I'm so glad to be one of the members who was chosen to participate in this amazing program. I'm looking forward to learning a lot from experienced tutors. Personalized batch (Maximum 15 students) Verbally ask question Lifetime dedicated what’s app group Premium resources",
              },
            ]?.map((cur, i) => (
              <Animator
                delay={i === 0 ? 400 : i === 1 ? 480 : i === 2 ? 550 : 600}
                timeout={i === 0 ? 400 : i === 1 ? 480 : i === 2 ? 550 : 600}
                variant="fade"
                key={i}
              >
                <div
                  style={{
                    width: mobile ? "100%" : "49%",
                    height: mobile ? "" : "50%",
                    background: "#fff",
                    borderRadius: 10,
                    padding: 23,
                    marginBottom: 10,
                  }}
                >
                  <h1
                    style={{
                      fontSize: 20,
                      fontWeight: 500,
                      color: "#434A54",
                    }}
                  >
                    {cur.name}
                  </h1>
                  <p
                    className="mt-4 text-gray-600"
                    style={{
                      fontSize: 14,
                    }}
                  >
                    {cur.details}
                  </p>
                </div>
              </Animator>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
