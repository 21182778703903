import React from "react";
import { useNavigate } from "react-router-dom";
import mailicon from "../../../assets/icons/mail.avif";
import { useIsMobile } from "../../hooks/useIsMobile";
import VonHeader from "../VonHeader/VonHeader";
export default function VonVerifyEmail() {
  const navigate = useNavigate();
  const mobile = useIsMobile();
  return (
    <>
      <VonHeader />
      <div className="custom-course-background min-h-screen flex items-center justify-center">
        <div
          className="bg-white p-8 rounded-lg shadow-lg w-full sm:w-96"
          style={{
            width: mobile ? "100%" : "40%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <img
            src={mailicon}
            style={{
              height: "250px",
              objectFit: "contain",
            }}
          />
          <h1
            style={{
              fontSize: mobile ? "20px" : "30px",
              fontWeight: "bold",
              textAlign: "center",
              marginBottom: "10px",
              marginTop: "10px",
              color: "#000",
            }}
          >
            Welcome to E1X Academy
          </h1>
          <p className="text-lg font-semibold mb-4 text-center"></p>
          <p className="text-gray-600 mb-4">
            Thank you for registering! A verification link has been sent to your
            email. Please check your inbox/spam folder to verify your account.
          </p>

          <button
            onClick={() => {
              navigate("/sign-in");
            }}
            className="bg-blue-500 text-white hover-bg-blue-600 py-2 px-4 rounded w-full"
          >
            Close
          </button>
        </div>
      </div>
    </>
  );
}
