import { doc, getDoc, setDoc } from "firebase/firestore";
import React, { useContext, useEffect, useRef, useState } from "react";
import {
  PiCaretLeftLight,
  PiCaretRightLight,
  PiListLight,
  PiXLight,
} from "react-icons/pi";
import {
  FaPlay,
  FaPause,
  FaVolumeUp,
  FaExpand,
  FaCompress,
} from "react-icons/fa";
import { Link, useNavigate, useParams } from "react-router-dom";
import client from "../../api/client";
import { GlobalContext } from "../../context";
import { database } from "../../firebase";
import ErrorPage from "../../page/ErrorPage";
import { FetchCurrentWatchingCourse, addObjectToFirebase } from "../../utility";
import LoadingSpinner from "../LoadingSpinner";
import CourseMenu from "./components/CourseMenu";
import { toast } from "react-toastify";
import { useIsMobile } from "../hooks/useIsMobile";
import VimeoPlayer from "./Videoplayer";

export default function Course() {
  const videoRef = useRef(null);
  const navigate = useNavigate();
  const [courses, setCourses] = useState();
  const { uuid } = useParams();
  const [loading, setLoading] = useState(false);

  const [videoUrl, setVideoUrl] = useState("");
  const [selectedLessonIndex, setSelectedLessonIndex] = useState(null);
  const [selectedLessonTitle, setSelectedLessonTitle] = useState("");
  const [courseMenu, setCourseMenu] = useState(false);

  const lessonId = courses?.lesson[selectedLessonIndex]?.uuid;


  const {
    authState: { data, loggedIn },
  } = useContext(GlobalContext);
  useEffect(() => {
    if (!loggedIn) {
      toast.error(`You must be logged in before accessing this page`);
      navigate("/sign-in");
    }
  }, []);
  const loadData = async () => {
    setLoading(true);
    try {
      const res = (
        await client.get(`/courses/online/online-courses/details/${uuid}`)
      ).data;

      setCourses(res);
    } catch (error) {
      console.log("error fetchimg course details", error.response);
    }
    setLoading(false);
  };
  const user = data?.user;
  const userId = user ? user?.id : null; // Check if user is not null before accessing id
  const mobile = useIsMobile();

  useEffect(() => {
    if (loggedIn) {
      loadData();
    }
  }, [uuid]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // End - Fetch progress on lesson change

  const AddWatchCourse = async (lessonInfo) => {
    const res = addObjectToFirebase(
      uuid,
      user?.id?.toString(),
      courses.course_id?.toString(),
      {
        ...courses,
        lessonInfo: lessonInfo,
      }
    );
  };
  // Start - Save progress during playback

  const handlePrevPage = () => {
    window.history.back();
  };

  const handleVideoClick = (
    lessonTitle,
    lessonDescription,
    videoLink,
    index
  ) => {
    setSelectedLessonTitle(lessonTitle);
    setSelectedLessonIndex(index);
    setVideoUrl(videoLink);
    setCourseMenu(false);
  };

  const currentWatchingStatus = async () => {
    const res = await FetchCurrentWatchingCourse(user?.id?.toString(), uuid);

    if (res.status) {
      handleVideoClick(
        res.data?.lessonInfo?.title,
        res.data?.lessonInfo?.description,
        res.data?.lessonInfo?.link,
        res.data?.lessonInfo?.index
      );
    } else {
      handleVideoClick(
        courses?.lesson[0]?.title,
        courses?.lesson[0]?.description,
        courses?.lesson[0]?.course_link,
        0
      );
    }
  };
  useEffect(() => {
    currentWatchingStatus();
  }, [uuid, userId, courses]);

  return (
    <div className="px-[5%]">
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          {user != null ? (
            <div>
              <div
                className={
                  courseMenu
                    ? "fixed px-[5%] z-[60] left-0 right-0 top-[15%] min-[420px]:top-[18%] sm:top-[20vh] flex justify-end bg-white py-2 md:hidden"
                    : "fixed px-[5%] z-[60] left-0 right-0 top-[15%] min-[420px]:top-[18%] sm:top-[20vh] flex justify-between bg-white py-2 md:hidden"
                }
              >
                <div
                  className={
                    courseMenu
                      ? "hidden"
                      : "flex items-center gap-2 text-[#227AFF] text-sm sm:text-base"
                  }
                  onClick={() => setCourseMenu(!courseMenu)}
                >
                  <div className="">
                    <PiListLight />
                  </div>
                  <p>COURSE MENU</p>
                </div>
                <div
                  className={
                    courseMenu
                      ? "flex items-center gap-2 text-[#227AFF] text-sm sm:text-base"
                      : "hidden"
                  }
                  onClick={() => setCourseMenu(!courseMenu)}
                >
                  <div className="">
                    <PiXLight />
                  </div>
                  <p>CLOSE MENU</p>
                </div>
              </div>
              <div
                style={{
                  overflowY: "scroll",
                }}
                className={
                  courseMenu
                    ? "block md:block fixed z-50 left-0 w-full bg-white top-[19vh] h-[81vh] md:w-[30%] md:shadow-lg"
                    : "hidden md:block fixed left-0 top-[19vh] h-[81vh] md:w-[30%] shadow-lg"
                }
              >
                <div className="">
                  <p className="text-black p-4 text-lg sm:text-xl font-medium">
                    Course Content
                  </p>
                  <div
                    className="border-t border-gray-200"
                    style={{
                      overflowY: "scroll",
                    }}
                  >
                    {courses?.lesson?.length > 0 &&
                      courses?.lesson?.map((lesson, index) => (
                        <CourseMenu
                          // change={progress}
                          course_id={uuid}
                          onClick={() => {
                            if (lesson?.course_link != null) {
                              AddWatchCourse({
                                title: lesson.title,
                                description: lesson.course_description,
                                link: lesson.course_link,
                                index: index,
                              });
                              handleVideoClick(
                                lesson.title,
                                lesson.course_description,
                                lesson.course_link,
                                index
                              );
                            } else {
                              toast.warning(
                                "Purchase the paid course to enjoy this course"
                              );
                              navigate(
                                "/online-course/5e45959e-6d17-4bfe-b568-2cb3ade6851e"
                              );
                            }
                          }}
                          {...lesson}
                          key={index}
                          index={index}
                          selectedLessonIndex={selectedLessonIndex}
                        />
                      ))}
                  </div>
                </div>
              </div>
              <div className="md:ml-[30%] md:w-[70%] mt-[5%] md:mt-0">
                <div className="flex justify-between mt-[40%] min-[420px]:mt-[45%] sm:mt-[25%] lg:mt-[20%]">
                  <div
                    className="flex items-center gap-2 cursor-pointer text-black hover:text-[#227AFF] delay-0 duration-150 transition-colors ease-out mb-[2.5%] w-fit"
                    onClick={handlePrevPage}
                  >
                    <div className="text-lg">
                      <PiCaretLeftLight />
                    </div>
                    <p className="text-xs min-[360px]:text-sm">
                      Back To Course
                    </p>
                  </div>
                  <Link
                    to="/all-courses"
                    className="flex items-center gap-2 cursor-pointer text-black hover:text-[#227AFF] delay-0 duration-150 transition-colors ease-out mb-[2.5%] w-fit"
                    onClick={handlePrevPage}
                  >
                    <p className="text-xs min-[360px]:text-sm">
                      Go To All Courses
                    </p>
                    <div className="text-lg">
                      <PiCaretRightLight />
                    </div>
                  </Link>
                </div>
                <div className="">
                  <p className="mb-[2%] font-medium uppercase">
                    {selectedLessonTitle}
                  </p>
                    {videoUrl != "" ? (
                      
                    <VimeoPlayer uuid={uuid} lessonId={lessonId} videoUrl={videoUrl} />
                  ) : (
                    // <video
                    //   src={videoUrl}
                    //   controls
                    //   width="100%"
                    //   controlsList="nodownload"
                    //   ref={videoRef}
                    // />
                    <img src={courses?.images} alt="imageLive" loading="lazy" />
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div>
              <ErrorPage
                error={{
                  message: "Unauthorised please login or refresh",
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
}
