import React from "react";
// import { a } from 'react-router-dom'
import { FaCcMastercard, FaCcPaypal, FaCcVisa } from "react-icons/fa";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaWhatsapp,
} from "react-icons/fa6";
import {
  PiClockFill,
  PiEnvelopeFill,
  PiMapPinFill,
  PiPhoneFill,
} from "react-icons/pi";
import { Link } from "react-router-dom";

export default function VonFooter() {
  const currentYear = new Date().getFullYear();

  return (
    <footer
      className="bg-[#F1F1F1] "
      style={{
        borderTopWidth: 1,
        borderColor: "#aaa",
      }}
    >
      <div className="innersection">
        <div className="  py-[5%] text-black flex flex-col md:flex-row justify-between gap-8 md:gap-4">
          <div className="flex-1 ">
            <h5 className="font-bold pb-2 mb-4 relative before:absolute before:bottom-0 before:left-0 before:w-[15%] before:h-[2px] before:bg-primary-color">
              Information
            </h5>
            <div className="flex flex-col space-y-4">
              <a
                to="/"
                className="text-sm transition-all delay-0 duration-200 hover:ml-2"
              >
                Home
              </a>
              {/* <a
              href=""
              className="text-sm transition-all delay-0 duration-200 hover:ml-2"
            >
              FAQ
            </a>
            <a
              href=""
              className="text-sm transition-all delay-0 duration-200 hover:ml-2"
            >
              Blog
            </a> */}
              <a
                href="/contact"
                className="text-sm transition-all delay-0 duration-200 hover:ml-2"
              >
                Contacts
              </a>
            </div>
          </div>
          <div className="flex-1">
            <h5 className="font-bold pb-2 mb-4 relative before:absolute before:bottom-0 before:left-0 before:w-[15%] before:h-[2px] before:bg-primary-color">
              Extras
            </h5>
            <div className="flex flex-col space-y-4">
              <a
                href="/dashboard"
                className="text-sm transition-all delay-0 duration-200 hover:ml-2"
              >
                My Profile
              </a>
              <a
                href="/dashboard"
                className="text-sm transition-all delay-0 duration-200 hover:ml-2"
              >
                My Course
              </a>
              <a
                href="/contact"
                className="text-sm transition-all delay-0 duration-200 hover:ml-2"
              >
                Our Location
              </a>
              <a
                href="/privacy-policy"
                className="text-sm transition-all delay-0 duration-200 hover:ml-2"
              >
                Privacy Policy
              </a>
            </div>
          </div>
          <div className="flex-1">
            <h5 className="font-bold pb-2 mb-4 relative before:absolute before:bottom-0 before:left-0 before:w-[15%] before:h-[2px] before:bg-primary-color">
              Get In Touch{" "}
            </h5>
            <div className="flex flex-col space-y-4">
              <div className="flex gap-2">
                <div className="text-[#aaaaaa]">
                  <PiClockFill />
                </div>
                <p className="text-sm">Mon. - Sat.: 8am - 6pm</p>
              </div>
              <div className="flex gap-2">
                <div className="text-[#aaaaaa]">
                  <PiMapPinFill />
                </div>
                <p className="text-sm">7404 Executive place, Lanham MD 20706, United States</p>
              </div>
              <div className="flex gap-2">
                <div className="text-[#aaaaaa]">
                  <PiPhoneFill />
                </div>
                <a to="tel:+14433321473" className="text-sm hover:underline">
                  +1 (240) 615-1548
                </a>
              </div>
              <div className="flex gap-2">
                <div className="text-[#aaaaaa]">
                  <PiEnvelopeFill />
                </div>
                <a
                  to="mailto:info@e1xacademy.net"
                  className="text-sm hover:underline"
                >
                  info@e1xacademy.net
                </a>
              </div>
            </div>
          </div>
          <div className="flex-1">
            <h5 className="font-bold pb-2 mb-4 relative before:absolute before:bottom-0 before:left-0 before:w-[15%] before:h-[2px] before:bg-primary-color">
              We Accept
            </h5>
            <div className="flex gap-2">
              <div className="text-5xl text-[#aaaaaa]">
                <FaCcMastercard />
              </div>
              <div className="text-5xl text-[#aaaaaa]">
                <FaCcVisa />
              </div>
              <div className="text-5xl text-[#aaaaaa]">
                <FaCcPaypal />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-[#d15424]">
        <div className="innersection block sm:flex gap-4 sm:gap-5 justify-between  py-[5%] md:py-[2%]">
          <div className="flex items-center gap-4 py-5  md:py-0">
            <Link
              to="https://www.facebook.com/share/AgqRHyhGV2UVNtqL/?mibextid=qi2Omg"
              className="text-lg text-[#fff] hover:text-[#5e5e5e] delay-0 duration-150 transition-colors ease-in"
            >
              <FaFacebookF />
            </Link>
            <Link
              to="https://www.instagram.com/e1xacademy_"
              className="text-lg text-[#fff] hover:text-[#5e5e5e] delay-0 duration-150 transition-colors ease-in"
            >
              <FaInstagram />
            </Link>
            <Link
              to="https://x.com/E1XAcademy"
              className="text-lg text-[#fff] hover:text-[#5e5e5e] delay-0 duration-150 transition-colors ease-in"
            >
              <FaTwitter />
            </Link>
            <Link
              to="http://wa.me/12029710392"
              className="text-lg text-[#fff] hover:text-[#5e5e5e] delay-0 duration-150 transition-colors ease-in"
            >
              <FaWhatsapp />
            </Link>
          </div>
          <small className="text-xs text-[#fff]">
            E1xacademy | &copy; {currentYear} All rights reserved!
          </small>
        </div>
      </div>
    </footer>
  );
}
