import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { GoSearch } from "react-icons/go";
import { MdOutlineNavigateNext } from "react-icons/md";
import { NavLink } from "react-router-dom";
import courseImg from "../../assets/images/course-img.png";
import ErrorPage from "../../page/ErrorPage";
import { CourseContext } from "../../services/CourseContext";
import LoadingSpinner from "../LoadingSpinner";
import CourseCard from "./components/CourseCard";

const CourseLatest = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredCourses, setFilteredCourses] = useState([]); // Store filtered course data
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [clickedCourseId, setClickedCourseId] = useState(null);
  const {
    courses,
    isLoading,
    error,

    fetchOnlineCourse,
  } = useContext(CourseContext);

  useEffect(() => {
    fetchOnlineCourse();
  }, []);

  const handleSearchInputChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  };

  const handleSearch = async () => {
    try {
      const response = await axios.post(
        "https://e1xstaging.edoubleone.com/api/online-courses/search/latest",
        { query: searchQuery }
      );

      const allCourses = response.data.courses;

      // Check if there's a clicked course and filter based on it
      if (clickedCourseId) {
        const clickedCourse = allCourses.find(
          (course) => course.id === clickedCourseId
        );

        if (clickedCourse) {
          setFilteredCourses([
            clickedCourse,
            ...allCourses.filter((course) => course.id !== clickedCourseId),
          ]);
        } else {
          setFilteredCourses(allCourses);
        }
      } else {
        // Filter courses based on the search query
        const filteredCourses = allCourses.filter((course) =>
          course.title.toLowerCase().includes(searchQuery.toLowerCase())
        );

        setFilteredCourses(filteredCourses);
      }

      setShowSearchResults(true);

      // Log the results
    } catch (error) {
      console.error("Error performing search:", error);
    }
  };

  // Function to reset to showing all courses
  // Function to reset to showing all courses
  const resetCourses = () => {
    setFilteredCourses([]);
    setShowSearchResults(false);
    setSearchQuery("");
    setClickedCourseId(null);
  };

  const imgVariants = {
    initial: {
      opacity: 0,
      y: 100,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 1,
      },
    },
  };

  return (
    <div className="lg:relative lg:overflow-auto">
      {isLoading ? (
        <LoadingSpinner />
      ) : error ? (
        <ErrorPage errors={error} />
      ) : (
        <>
          <div className="background-card h-[450px] lg:absolute lg:top-0 lg:-z-0 w-full">
            <div className="max-w-6xl mx-auto px-4">
              <div className="relative flex flex-col md:flex-row">
                <div className="lg:w-1/2 w-full lg:mt-12">
                  <div className="flex flex-col">
                    <div className="flex items-center text-blue-400 ">
                      <NavLink to="/">Home</NavLink>
                      <MdOutlineNavigateNext />
                      <p>Courses</p>
                    </div>
                    <div className="lg:mt-12 mt-4 lg:space-y-2">
                      <h1 className="font-bold lg:text-5xl text-2xl">
                        Courses
                      </h1>
                      <p className="lg:font-bold "></p>
                    </div>
                    <div className="relative flex items-center gap-6 lg:mt-28 mt-6">
                      <button className="w-24 text-center bg-white lg:text-sm text-xs py-3 rounded-lg text-blue-400">
                        Course
                      </button>
                      <button className="w-24 text-center bg-white lg:text-sm text-xs py-3 rounded-lg text-blue-400">
                        Popular
                      </button>
                      <button className="w-24 text-center bg-white lg:text-sm text-xs py-3 rounded-lg text-blue-400">
                        Latest
                      </button>
                      <button className="w-24 text-center bg-white lg:text-sm text-xs py-3 rounded-lg text-blue-400 ">
                        Trending
                      </button>
                    </div>
                  </div>
                </div>
                <div className="lg:w-1/2 w-full">
                  <div className="flex flex-col">
                    <div className="flex lg:justify-start  justify-center ">
                      <img
                        src={courseImg}
                        alt="course-img"
                        className="mx-auto flex justify-center"
                      />
                    </div>
                    <div className=" flex lg:justify-end justify-center ">
                      <div class="relative ">
                        <input
                          type="text"
                          className="bg-white h-10 px-10 pl-10 text-sm focus:outline-none lg:w-64 w-full"
                          placeholder="SEARCH COURSE"
                          value={searchQuery}
                          onChange={handleSearchInputChange} // Update searchQuery as the user types
                        />

                        <div className="absolute top-0 left-0 mt-3 ml-3">
                          <GoSearch onClick={handleSearch} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="w-full lg:mt-[380px] lg:z-50 lg:relative px-2">
            <div className="lg:-z-50">
              <div className="mb-6">
                {showSearchResults ? (
                  filteredCourses.length > 0 ? (
                    filteredCourses.map((course) => (
                      <CourseCard
                        key={course.id}
                        images={course.images}
                        title={course.title}
                        price={course.price}
                        beginnerText={course.course_level_id}
                        lessonsText={course.lesson_count}
                        hours={course.duration}
                        videos="30"
                        rating="4.3"
                        currency={course.currency}
                        instructor={course.instructors}
                        uuid={course.uuid}
                      />
                    ))
                  ) : (
                    <p className="text-center text-red-500">
                      No courses match the search query.
                      <button onClick={resetCourses}>Show All Courses</button>
                    </p>
                  )
                ) : (
                  // Show all courses when not searching
                  courses.map((course) => (
                    <CourseCard
                      key={course.id}
                      images={course.images}
                      title={course.title}
                      price={course.price}
                      beginnerText={course.course_level_id}
                      lessonsText={course.lesson_count}
                      hours={course.duration}
                      videos="30"
                      rating="4.3"
                      currency={course.currency}
                      instructor={course.instructors}
                      uuid={course.uuid}
                    />
                  ))
                )}
              </div>

              <div className="flex justify-center p-4 mb-4    text-[#e46601] ">
                {showSearchResults && (
                  <button onClick={handleSearch}>Show All Courses</button>
                )}
              </div>
            </div>
          </div>
          {/* <div className="flex justify-center p-4 mb-4 text-[#e46601]">
            {pageNumbers.map((number) => (
              <button
                key={number}
                onClick={() => {
                  setCurrentPage(number); // Update the current page
                  fetchPaginatedData(number, itemsPerPage); // Fetch data for the selected page
                }}
                className={`${
                  currentPage === number
                    ? "bg-[#e46601] text-white"
                    : "bg-white text-[#e46601] hover:bg-gray-200"
                } px-4 py-2 rounded mx-2 transition duration-300`}
              >
                {number}
              </button>
            ))}
          </div> */}
        </>
      )}
    </div>
  );
};

export default CourseLatest;
