import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalContext } from "../../context";
import VonDisplayText from "../AllVon/VonDisplayText/VonDisplayText";
import Discount from "./Discount";

function CourseComponent({
  image_url,
  title,
  discount,
  id,
  currency,
  price,
  uuid,
  description,
}) {
  const htmlContentFromAPI = description;
  const { userLocation } = useContext(GlobalContext);
  const navigate = useNavigate();
  const handleViewDetails = () => {
    navigate(`/live-course/${uuid}`);
  };
  return (
    <div
      style={{
        background: `url(${image_url})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundBlendMode: "darken",
        borderRadius: 15,
        boxShadow: "0px 10px 20px rgba(0, 0, 0, 0.1)",
        transition: "all 0.3s ease-in-out",
      }}
      onClick={() => handleViewDetails()}
      className="relative w-full min-[480px]:w-[100%] md:w-[100%]  h-[320px] cursor-pointer p-4 overflow-hidden group text-white before:absolute before:bg-[#00000086] before:w-full before:h-full before:top-0 before:left-0 before:rounded-lg mb-[1%]"
    >
      {discount && userLocation.data === "NG" && (
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
        >
          <div style={{}}>
            <Discount value={discount} />
          </div>
        </div>
      )}

      <div className="absolute bottom-[8%] hover:bottom-[20%] translate-y-[85%] group-hover:translate-y-1/4 delay-0 duration-200 transition-transform ease-[ease]">
        {/* <p>{course_level_id}</p> */}
        <p
          style={{
            marginBottom: 20,
          }}
        >
          {title}
        </p>
        {/* <div className="flex gap-1 mb-[7%] invisible">
          <p>{currency}</p>
          <p>{price}</p>
        </div> */}
        <p className="">
          <VonDisplayText htmlContent={htmlContentFromAPI} maxLength={150} />
        </p>
      </div>
    </div>
  );
}
export default CourseComponent;
